import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { LocalDataSource } from 'ng2-smart-table';

@Component({
    selector: 'table-takings',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
            ></ng2-smart-table>`,
    styleUrls: ['./table.takings.component.css']
})
export class TableTakingsComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: true,
        actions: {
            add: false,
            edit: false,
            delete: false
        },
        columns: {
            time: {
                title: "Date"
            },
            takings: {
                title: "Chiffre d'affaires HT"
            },
            instoreTakings: {
                title: "Chiffre d'affaires Symbioz HT"
            },
            count: {
                title: "Commandes"
            },
            average: {
                title: "Panier moyen"
            },
            channelsString: {
                title: "Canal de distrution"
            },
            canalsString: {
                title: "Canal de ventes"
            }
        }
    }
    
    @Input()
    public data: Array<any>

    constructor() { }


    public ngOnInit(): void {
        this.source = new LocalDataSource(this.data
            .map(kpi => {
                const d = { ...kpi }
                d.takings = `${d.takings.toFixed(2)} HT
                    -
                    ${d.takings_wvat.toFixed(2)} TTC`
                
                d.instoreTakings = `${(d.instoreTakings + d.readjustments_novat).toFixed(2)} HT
                    -
                    ${(d.instoreTakings_wvat + d.readjustments).toFixed(2)} TTC`
                
                d.average = `${(d.averageBasket.exclTaxAmount || 0).toFixed(2)} HT
                    -
                    ${(d.averageBasket.inclTaxAmount || 0).toFixed(2)} TTC`

                d.channelsString = ""
                if (d.channels.instant.count > 0) d.channelsString += `${((d.channels.instant.count/d.count)*100).toFixed(0)}% Comptant `
                if (d.channelsString.length > 0 && d.channels.dinering.count) d.channelsString += " - "
                if (d.channels.dinering.count > 0) d.channelsString += `${((d.channels.dinering.count/d.count)*100).toFixed(0)}% Sur place `
                if (d.channelsString.length > 0 && d.channels.ordering.count > 0) d.channelsString += " - "
                if (d.channels.ordering.count > 0) d.channelsString += `${((d.channels.ordering.count/d.count)*100).toFixed(0)}% A emporter `
                if (d.channelsString.length > 0 && d.channels.shipping.count > 0) d.channelsString += " - "
                if (d.channels.shipping.count > 0) d.channelsString += `${((d.channels.shipping.count/d.count)*100).toFixed(0)}% En livraison `

                d.canalsString = ""
                if (d.canals.counter.count > 0) d.canalsString += `${((d.canals.counter.count/d.count)*100).toFixed(0)}% Comptoir `
                if (d.canalsString.length > 0 && d.canals.estore.count) d.canalsString += " - "
                if (d.canals.estore.count > 0) d.canalsString += `${((d.canals.estore.count/d.count)*100).toFixed(0)}% Web `
                if (d.canalsString.length > 0 && d.canals.easyorder.count > 0) d.canalsString += " - "
                if (d.canals.easyorder.count > 0) d.canalsString += `${((d.canals.easyorder.count/d.count)*100).toFixed(0)}% Borne `
                if (d.canalsString.length > 0 && d.canals.drive.count > 0) d.canalsString += " - "
                if (d.canals.drive.count > 0) d.canalsString += `${((d.canals.drive.count/d.count)*100).toFixed(0)}% Drive `
                if (d.canalsString.length > 0 && d.canals.ubereats.count > 0) d.canalsString += " - "
                if (d.canals.ubereats.count > 0) d.canalsString += `${((d.canals.ubereats.count/d.count)*100).toFixed(0)}% Plateformes `
            
                return d
            }))
    }    
}