import { Injectable } from '@angular/core'
import { Httpclient } from "./Httpclient.service"

import { Establishment } from "app/models/establishment/Establishment"
import { Status } from 'app/models/Status';
import { User } from 'app/models';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class EstablishmentService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["establishment"].get()
    }

    public async getByCustomer (customerId: number): Promise<Array<Establishment>> {
        try {
            let res = await this._Httpclient.get(`${this.config.endpoints["auth"].get()}/sbz/establishment?customer=${customerId}`, btoa(`${this.config.username}:${this.config.password}`))
            let establishments = res.map(establishment => new Establishment(establishment.establishment_id, establishment.trade_name, establishment.access_key, establishment.version))
            return Promise.resolve(establishments)
        } catch (error) {
            console.error("EstablishmentService::get", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async getUsersByEstablishmentId (id: number): Promise<Array<User>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}/user`)
            let users = res.map(user => new User(user.user_id, user.first_name, user.last_name, user.phone, user.email))
            return Promise.resolve(users)
        } catch (error) {
            console.error("EstablishmentService::getUsersByEstablishmentId", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async find (id: number): Promise<Establishment> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let establishment = new Establishment(
                res.establishment_id,
                res.trade_name,
                res.access_key,
                res.version,
                true,
                res.trade_name,
                res.corporate_name,
                res.tva,
                res.siret,
                res.naf,
                res.longitude,
                res.latitude,
                res.phone,
                res.address,
                res.postal_code,
                res.city,
                res.build_number,

                res.deliverect_location_id,
                res.deliverect_account_id,

                res.place_id,
                // res.deliverect_client_id,
                // res.deliverect_client_secret
                res.countryCode
            )
            return Promise.resolve(establishment)
        } catch (error) {
            console.error("EstablishmentService::find", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async addUser (id: number, user: User): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/${id}/user`, {
                first_name: user.firstName,
                last_name: user.lastName,
                phone: user.phone,
                email: user.email
            })
            if (res.user_id) res.lastInsertId = res.user_id
            return Promise.resolve(res)
        } catch (error) {
            console.error("EstablishmentService::addUser", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async update (id: number, establishment: Establishment): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, establishment)
            return Promise.resolve(res)
        } catch (error) {
            console.error("EstablishmentService::update", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async updateUser (id: number, userId: number, user: User): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/user/${userId}`, {
                first_name: user.firstName,
                last_name: user.lastName,
                phone: user.phone,
                email: user.email
            })
            return Promise.resolve(res)
        } catch (error) {
            console.error("EstablishmentService::updateUser", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async deleteUser (id: number, userId: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}/user/${userId}`)
            return Promise.resolve(res)
        } catch (error) {
            console.error("EstablishmentService::deleteUser", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
}