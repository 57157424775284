import { InventoryLine } from "./InventoryLine";
import { Staff } from "..";

export class Inventory {
    public inventory_id: number
    public staff_id: number
    public date: number
    public inventory_line: Array<InventoryLine>
    public staff: Staff

    constructor(
        inventory_id?: number,
        staff_id?: number,
        date?: number,
        inventory_line?: Array<InventoryLine>,
        staff?: Staff
    ) {
        this.inventory_id = inventory_id
        this.staff_id = staff_id
        this.date = date
        this.inventory_line = inventory_line
        this.staff = staff
    }
}