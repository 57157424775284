import { Association } from "./Association";
import { Product } from "..";

export class CategoryProduct {
    // Cateory set
    public category_set_id: number
    // category extend
    public category_extend_id: number
    // Common
    public name: string
    public code: string
    public iconCode: string
    public min: number
    public max: number
    public designation: string
    public description: string
    // public product: Array<Association>
    public product: Array<Product>
    public type: string
    public order: number
    public category_id: number
    public plu: string

    public bogof: boolean
    public primary: boolean

    public static custom: CategoryProduct = new CategoryProduct(null, null, null, null, null, [], 99999, "CUSTOM")

    constructor (
        name?: string, 
        code?: string, 
        iconCode?: string, 
        min?: number, 
        max?: number, 
        // product: Array<Association> = [], 
        product: Array<Product> = [], 
        category_id?: number, 
        type?: string,
        order?: number,
        designation?: string,
        description?: string,
        plu?: string,
        bogof: boolean = false,
        primary: boolean = false
    ) {
        this.name = name
        this.iconCode = iconCode
        this.min = min
        this.max = max
        this.product = product
        this.category_id = category_id
        if (type === "SET") this.category_set_id = category_id
        if (type === "EXTEND") this.category_extend_id = category_id
        this.type = type
        this.order = order
        this.designation = designation
        this.description = description
        this.code = code
        this.plu = plu

        this.bogof = bogof
        this.primary = primary
    }
}