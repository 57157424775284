import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'
import { Color } from 'app/models';


@Component({
  template: `
  <i class="material-icons" [ngStyle]="{'color':rgb}">lens</i>
  <div class="ripple-container"></div>
  `,
})
export class ProductColorRenderComponent implements ViewCell, OnInit {

  @Input() value: string
  @Input() rowData: any

  public colors: Array<Color> = Color.list
  public rgb

  constructor () { }

  public ngOnInit (): void {
    this.rgb = `rgb(${this.colors.filter(color => color.value === this.value)[0].rgb})`
    
  }

}