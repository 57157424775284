export class Unit {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Unit> = [
        new Unit("Autre", "UNIT"),
        new Unit("Ramette", "RAMETTTE"),
        new Unit("Pièce", "PIECE"),
        new Unit("Boite", "BOITE"),
        new Unit("Colis", "COLIS"),
        new Unit("Carton", "CARTON"),
        new Unit("Mililitres", "ML"),
        new Unit("Centilitres", "CL"),
        new Unit("Litres", "L"),
        new Unit("Miligrammes", "MG"),
        new Unit("Grammes", "GR"),
        new Unit("Kilogrammes", "KG"),
        new Unit("Milimètres", "MM"),
        new Unit("Centimètres", "CM"),
        new Unit("Mètres", "M"),
    ]
}