import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms'
import { Product, Vat, Type } from 'app/models'
import { AppService } from 'app/app.service';

@Component({
    selector: 'product-dialog',
    template: `
    <h1 mat-dialog-title>Ajouter un produit</h1>
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput id="name" [(ngModel)]="product.name" formControlName="name" placeholder="Nom du produit">
                    </mat-form-field>
                </div>
                <div class="col-md-6 product-padding">
                    <select class="product-select" [(ngModel)]="product.type" formControlName="type">
                        <option value="" class="product-option" disabled selected>Type de produit</option>
                        <option *ngFor="let t of types" class="product-option" [ngValue]="t.value">
                            {{t.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="product.type && product.type !== 'SET'">
                <div class="col-md-8">
                    <mat-form-field class="example-full-width">
                        <input matInput id="price_wvat" [(ngModel)]="product.product_price.price_wvat" formControlName="price_wvat" placeholder="Prix TTC">
                    </mat-form-field>
                </div>
                <div class="col-md-4 product-padding">
                    <select class="product-select" [(ngModel)]="product.product_price.vat" formControlName="vat">
                        <option value="" class="product-option" disabled selected>Taux de TVA</option>
                        <option *ngFor="let v of vats" class="product-option" [ngValue]="v.value">
                            {{v.label}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="product" [ngClass]="{'disabled': !form.valid}">Valider</button>
    <button mat-button (click)="onNoClick()">Fermer</button>
    </div>
    `,
  })
  export class ProductDialog implements OnInit {

    public product: Product = new Product()
    public vats: Array<Vat> = AppService.vats
    public types: Array<Type> = Type.list

    public form: FormGroup
    public name: AbstractControl
    public type: AbstractControl
    public price_wvat: AbstractControl
    public vat: AbstractControl
  
    constructor(
        private _FormBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ProductDialog>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {}

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'name': ['', [Validators.required]],
            'type': ['', [Validators.required]],
            'price_wvat': [''],
            'vat': ['']
        })
        this.name = this.form.controls['name']
        this.type = this.form.controls['type']
        this.price_wvat = this.form.controls['price_wvat']
        this.vat = this.form.controls['vat']
    }

    public onNoClick(): void {
      this.dialogRef.close()
    }
  
  }