import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { ProductionLine } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class ProductionLineService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["production"].get()
    }

    public async get (): Promise<Array<ProductionLine>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/lines`)
            let productionLines = res.map(productionLine => new ProductionLine(
                productionLine.name,
                productionLine.code,
                productionLine.color,
                productionLine.icon,
                productionLine.order,
                productionLine.production_line_id
            ))
            return Promise.resolve(productionLines)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<ProductionLine> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/lines/${id}`)
            let productionLine = new ProductionLine(
                res.name,
                res.code,
                res.color,
                res.icon,
                res.order,
                res.production_line_id
            )
            return Promise.resolve(productionLine)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (productionLine: ProductionLine): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/lines`, productionLine)
            if (res.production_line_id) res.lastInsertId = res.production_line_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, productionLine: ProductionLine): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/lines/${id}`, productionLine)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/lines/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}