import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'save-dialog',
    template: `
    <h1 mat-dialog-title>Souhaitez-vous enregistrer les modifications ?</h1>
    <div mat-dialog-content></div>
    <div mat-dialog-actions>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Enregistrer</button>
        <button mat-button (click)="onNoClick()">Ne pas sauvegarder</button>
    </div>
    `,
  })
  export class SaveDialog {
  
    constructor(
      public dialogRef: MatDialogRef<SaveDialog>,
      @Inject(MAT_DIALOG_DATA) public data: string) {}
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  
  }