import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell } from 'ng2-smart-table'
import { CopyDialog } from 'app/components/dialog/copy.dialog'


@Component({
  template: `
  <i class="material-icons" style="color: #ccc; cursor: pointer;" (click)="handleCopy()">content_copy</i>
  <div class="ripple-container"></div>
  `,
})
export class CopyRenderComponent implements ViewCell, OnInit {

  @Input() value: number
  @Input() rowData: any

  @Output() copiedData: EventEmitter<number> = new EventEmitter()

  constructor (
      private _Dialog: MatDialog
  ) { }

  public ngOnInit (): void {
    
  }


  public async handleCopy (): Promise<void> {
    try {
      let dialog = this._Dialog.open(CopyDialog, {width: '400px'})
      let result = await dialog.afterClosed().toPromise()
      this.copiedData.emit(this.value)
      if (!result) return Promise.resolve()
    } catch (error) {
      console.error("CopyRenderComponent::handleCopy", error, this)
    }
  }
}

