import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppService } from 'app/app.service';

interface Data {
    title: string
    date: string
    billing: {
        editing_date: string
        total_novat: string
        payment_date: string
        status: "WAITED" | string
        provider_billing_line: {
            quantity: string
            total_novat: string
            stock: {
                nomination: string
            }
        }[]
    }
}

@Component({
    selector: 'billing-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <p>Date : {{data.date}}</p>
      <p>Total HT : {{data.billing.total_novat}} {{ currency }}</p>
      <table class="table table-hover">
        <thead class="text-info">
            <th class="table-action">Element de stock</th>
            <th>Quantité</th>
            <th>Total HT</th>
        </thead>
        <tbody>
            <tr *ngFor="let line of data.billing.provider_billing_line">
                <td>{{line.stock.nomination}}</td>
                <td>{{line.quantity}}</td>
                <td>{{line.total_novat}} {{ currency }}</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Fermer</button>
    </div>
    `,
  })
  export class BillingDialog implements OnInit {

    public currency: string = AppService.currency

    constructor(
      public dialogRef: MatDialogRef<BillingDialog>,
      @Inject(MAT_DIALOG_DATA) public data: Data) {}

    public ngOnInit(): void {
        this.data["date"] = (this.data["billing"].status === "WAITED")
            ? this.data["billing"].editing_date
            : this.data["billing"].payment_date
    }

    public onNoClick(): void {
      this.dialogRef.close();
    }

  }
