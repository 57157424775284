import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { ViewCell, DefaultEditor } from 'ng2-smart-table'
import { Router } from '@angular/router';


@Component({
  template: `
  <i class="material-icons" style="cursor: pointer" (click)="handleClick()">search</i>
  <div class="ripple-container"></div>
  `,
})
export class BillingEditorComponent extends DefaultEditor implements OnInit {
  
  public value: number

  constructor(
    private _Router: Router 
  ) {
    super();
  }

  public ngOnInit(): void {
    this.value = this.cell.getValue()
  }

  public handleClick(): void {
    this._Router.navigateByUrl(`/store/provider/${this.value}`)
  }

}

