import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell } from 'ng2-smart-table'
import { PasswordDialog } from 'app/components/dialog/password.dialog';


@Component({
  template: `
  <i class="material-icons" style="color: #ccc">vpn_key</i>
  <div class="ripple-container"></div>
  `,
})
export class PasswordRenderComponent implements ViewCell, OnInit {

  @Input() value: string
  @Input() rowData: any

  constructor (
      private _Dialog: MatDialog
  ) { }

  public ngOnInit (): void {

  }

}

