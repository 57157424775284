import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Status } from 'app/models/Status'
import { OpeningHour } from "app/models"
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class OpeningHourService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints.hour.get()
    }

    public async get (): Promise<Array<OpeningHour>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let openingHours = res.map(openingHour => new OpeningHour(
                openingHour.startAt,
                openingHour.endAt,
                openingHour.days,
                openingHour.id
                
            ))
            return Promise.resolve(openingHours)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (openingHour: OpeningHour): Promise<Status> {
        try {
            let body = { ...OpeningHour.format(openingHour) }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.id) res.lastInsertId = res.id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, openingHour: OpeningHour): Promise<Status> {

        console.log({id, openingHour})
        try {
            let body = { ...OpeningHour.format(openingHour) }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}