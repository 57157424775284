import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service';
import { Printer, PrinterTarget } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class PrinterService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["printer"].get()
    }

    public async get (): Promise<Array<Printer>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/all`)
            let printers = res.map(printer => new Printer(
                printer.printer_id,
                printer.name,
                printer.kind,
                printer.placement,
                printer.target.map(target => new PrinterTarget(target.model, target.mac_address, res.printer_id, target.printer_target_id)),
                (printer.cash_drawer === "2PIN"),
                (printer.buzzer === "TRUE"),
                (printer.ticket_pass === "TRUE"),
                (printer.ticket_product === "TRUE")
            ))
            return Promise.resolve(printers)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Printer> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let printer = new Printer(
                res.printer_id,
                res.name,
                res.kind,
                res.placement,
                res.target.map(target => new PrinterTarget(target.model, target.mac_address, res.printer_id, target.printer_target_id)),
                (res.cash_drawer === "2PIN"),
                (res.buzzer === "TRUE"),
                (res.ticket_pass === "TRUE"),
                (res.ticket_product === "TRUE")
            )
            return Promise.resolve(printer)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (printer: Printer): Promise<Status> {
        try {
            let body = {
                ...printer,
                cash_drawer: (printer.cash_drawer) ? "2PIN" : "NONE",
                buzzer: (printer.buzzer) ? "TRUE" : "FALSE",
                ticket_pass: (printer.ticket_pass) ? "TRUE" : "FALSE",
                ticket_product: (printer.ticket_product) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.printer_id) res.lastInsertId = res.printer_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, printer: Printer): Promise<Status> {
        try {
            let body = {
                ...printer,
                cash_drawer: (printer.cash_drawer) ? "2PIN" : "NONE",
                buzzer: (printer.buzzer) ? "TRUE" : "FALSE",
                ticket_pass: (printer.ticket_pass) ? "TRUE" : "FALSE",
                ticket_product: (printer.ticket_product) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}