import { Component, Input, OnInit, AfterViewInit } from '@angular/core'
import { ViewCell, DefaultEditor } from 'ng2-smart-table'


@Component({
  template: `
  <input type="hidden" name="toggle" [(ngModel)]="value">
  <toggle [on]="value" (toggled)="handleToggle($event)"></toggle>
  `,
})
export class ToggleEditorComponent extends DefaultEditor implements OnInit {
  
  public value: string

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.value = this.cell.getValue()
  }

  public handleToggle($event): void {
    this.cell.newValue = this.value = $event
  }  
}