import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Provider, Role, ProviderBilling } from 'app/models'
import { ProviderService } from 'app/services'

import { ColorRenderComponent } from 'app/components/cell/render/color.render.component'
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component'
import { PasswordRenderComponent } from 'app/components/cell/render/password.render.component'

import { ColorEditorComponent } from 'app/components/cell/editor/color.editor.component'
import { PasswordEditorComponent } from 'app/components/cell/editor/password.editor.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';
import { BillingRenderComponent } from 'app/components/cell/render/billing.render.component';
import { BillingEditorComponent } from 'app/components/cell/editor/billing.editor.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
    selector: 'table-provider',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.provider.component.css']
})
export class TableProviderComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: false,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            provider_id: {
                title: 'Voir',
                editable: false,
                filter: false,
                type: 'custom',
                renderComponent: BillingRenderComponent,
                editor: {
                    type: 'custom',
                    component: BillingEditorComponent
                }
            },
            trade_name: {
                title: 'Raison social'
            },
            corporate_name: {
                title: 'Nom commercial'
            },
            siret: {
                title: 'SIRET'
            },
            address: {
                title: 'Adresse'
            },
            postal_code: {
                title: 'Code postal'
            },
            city: {
                title: 'Ville'
            },
            phone: {
                title: 'Téléphone'
            },
            purchase: {
                editable: false,
                filter: false,
                title: 'Bons de commande'
            },
            billing: {
                editable: false,
                filter: false,
                title: 'Factures'
            },
        }
    }
    
    @Input()
    public providers: Array<Provider>
    @Input()
    public billings: Array<ProviderBilling>
    @Input()
    public purchases: Array<ProviderBilling>

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _ProviderService: ProviderService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }


    public ngOnInit(): void {
        this.providers.shift()
        this.source = new LocalDataSource(this.providers.map(provider => {
            provider["purchase"] = `${this.purchases.filter(purchase => purchase.provider_id === provider.provider_id).length}`// en cours`
            provider["billing"] = `${this.billings.filter(billing => billing.provider_id === provider.provider_id).length}`// facturés`
            provider["provider_id"] = provider.provider_id
            return provider
        }))
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let provider = {...$event.newData}
            delete provider.provider_id
            delete provider.purchase
            delete provider.billing
            let res = await this._ProviderService.add(provider)
            $event.newData.provider_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProviderComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout du produit.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let provider = {...$event.newData}
            delete provider.purchase
            delete provider.billing
            await this._ProviderService.edit($event.data.provider_id, provider)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProviderComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la modification du produit.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._ProviderService.delete($event.data.provider_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProviderComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du produit.")
            return Promise.reject(error)
        }
    }
    
}