import { Input, Component, OnInit } from "@angular/core";
import { ProviderBilling, ProviderBillingLine } from "app/models";
import { MatDialog } from "@angular/material";
import { BillingSearchRenderComponent } from "app/components/cell/render/billing.search.render.component";
import { LocalDataSource } from "ng2-smart-table";

@Component({
    selector: 'table-billing',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
            ></ng2-smart-table>`,
    // templateUrl: `./table.billing.component.html`,
    styleUrls: ['./table.billing.component.css']
})
export class TableBillingComponent implements OnInit {

    @Input()
    public billings: Array<ProviderBilling>
    @Input()
    public kind: string

    public source: LocalDataSource

    public settings = {
        actions: {
            columnTitle: '',
            add: false,
            edit: false,
            delete: false
        },
        columns: {
            provider_billing_id: {
                title: 'Voir',
                filter: false,
                type: 'custom',
                renderComponent: BillingSearchRenderComponent
            },
            date: {
                title: 'Date'
            },
            total_novat: {
                title: 'Total HT'
            }
        }
    }

    constructor () { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.billings.map(billing => {
            return {
                provider_billing_id: billing.provider_billing_id,
                date: (this.kind === "PURCHASE") ? billing.editing_date : billing.payment_date,
                total_novat: billing.total_novat
            }
        }))
    }
}