import { PrinterTarget } from "./PrinterTarget";

export class Printer {

    public printer_id: number
    public name: string
    public kind: string
    public placement: string
    public target: Array<PrinterTarget>
    public cash_drawer: boolean
    public buzzer: boolean
    public ticket_pass: boolean
    public ticket_product: boolean
    
    constructor (printer_id?: number, name?: string, kind?: string, placement?: string, target: Array<PrinterTarget> = [], cash_drawer: boolean = false, buzzer: boolean = false, ticket_pass: boolean = false, ticket_product: boolean = false) {
        this.printer_id = printer_id
        this.name = name
        this.kind = kind
        this.placement = placement
        this.target = target
        this.cash_drawer = cash_drawer
        this.buzzer = buzzer
        this.ticket_pass = ticket_pass
        this.ticket_product = ticket_product
    }
}