export class Vat {
    public label: string
    public value: number

    constructor (label: string, value: number) {
        this.label = label
        this.value = value
    }

    public static list: Array<Vat>
}