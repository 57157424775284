import { Injectable } from '@angular/core'
import { Md5 } from 'ts-md5'

import { Httpclient } from './Httpclient.service'
import { Inventory, InventoryLine } from "app/models"
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class InventoryService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["inventory"].get()
    }

    public async get (): Promise<Array<Inventory>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let inventories = res.map(inventory => new Inventory(
                inventory.inventory_id,
                inventory.staff_id,
                inventory.date,
                [],
                inventory.staff
            ))
            return Promise.resolve(inventories)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Inventory> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let inventory = new Inventory(
                res.inventory_id,
                res.staff_id,
                res.date,
                res.inventory_line.map(line => new InventoryLine(
                    line.inventory_line_id,
                    line.inventory_id,
                    line.stock_id,
                    line.theoritical_quantity,
                    line.real_quantity,
                    line.stock
                ))
            )
            return Promise.resolve(inventory)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}