import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Vat } from 'app/models';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { OpeningHour } from 'app/models/establishment/OpeningHour';
import { AppService } from 'app/app.service';

@Component({
    selector: 'card-openingHour',
    templateUrl: './card.openingHour.component.html',
    styleUrls: ['./card.openingHour.component.css']
})
export class CardOpeningHourComponent implements OnInit {
    
    @Input() openingHour: OpeningHour
    @Input() index: number
    
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|OpeningHour|boolean>> = new EventEmitter
    
    public form: FormGroup
    public monday: AbstractControl
    public tuesday: AbstractControl
    public wednesday: AbstractControl
    public thursday: AbstractControl
    public friday: AbstractControl
    public saturday: AbstractControl
    public sunday: AbstractControl
    public start: AbstractControl
    public end: AbstractControl

    public readonly: boolean = false
    public create: boolean = false
    public vats: Array<Vat> = AppService.vats


    constructor(
        private _FormBuilder: FormBuilder,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'monday': [true],
            'tuesday': [true],
            'wednesday': [true],
            'thursday': [true],
            'friday': [true],
            'saturday': [true],
            'sunday': [true],
            'start': ['00:00:00'],
            'end': ['23:59:59']
        })
        this.monday = this.form.controls['monday']
        this.tuesday = this.form.controls['tuesday']
        this.wednesday = this.form.controls['wednesday']
        this.thursday = this.form.controls['thursday']
        this.friday = this.form.controls['friday']
        this.saturday = this.form.controls['saturday']
        this.sunday = this.form.controls['sunday']
        this.start = this.form.controls['start']
        this.end = this.form.controls['end']
    }
    
    public handleRemove(): void {
        this.onRemove.emit(this.index)
    }
    public handleChange(): void {
        this.onChange.emit([this.index, this.openingHour])
    }
}