export class Staff {

    public staff_id: number
    public first_name: string
    public last_name: string
    public role: string

    public color: string
    public email: string
    public login: string
    public phone: string
    public password: string
    public oldPassword: string

    public reduction: boolean
    public cash_drawer: boolean
    public remove_order: boolean
    public remove_quantity: boolean
    public payment: boolean
    public last_print: boolean

    public socialSecurityNumber: string

    constructor (
        staff_id?: number, 
        first_name?: string, 
        last_name?: string, 
        role?: string, 
        color?: string, 
        email?: string, 
        login?: string, 
        phone?: string, 
        reduction: boolean = true, 
        cash_drawer: boolean = true, 
        remove_order: boolean = true, 
        remove_quantity: boolean = true, 
        payment: boolean = true, 
        password: string = '',
        socialSecurityNumber: string = '',
        last_print: boolean = true
    ) {
        this.staff_id = staff_id
        this.first_name = first_name
        this.last_name = last_name
        this.role = role

        this.color = color
        this.email = email
        this.login = login
        this.phone = phone
        this.oldPassword = password
        this.password = ''

        this.reduction = reduction
        this.cash_drawer = cash_drawer
        this.remove_order = remove_order
        this.remove_quantity = remove_quantity
        this.payment = payment
        this.last_print = last_print

        this.socialSecurityNumber = socialSecurityNumber
    }
}