export class ProductItem {
    public product_item_id: number
    public product_id: number
    public name: string
    public quantity: number
    public stock_id: number
    public type: string

    public weight: number
    public printer_bold: string

    public min: number
    public max: number
    public order: number
    public picture: string
    public isRemovable: boolean
    public isBold: boolean

    public code: string
    // public description: string

    public static custom: ProductItem = new ProductItem(null, null, null, null, null, null, "CUSTOM")

    constructor (
        product_id: number, 
        name?: string,
        code?: string, 
        quantity?: number, 
        stock_id?: number, 
        product_item_id?: number, 
        type?: string, 
        min? : number, 
        max?: number, 
        order?: number, 
        picture?: string, 
        isBold: boolean = false, 
        weight?: number
        // description: string = ''
    ) {
        this.product_id = product_id
        this.product_item_id = product_item_id
        this.name = name
        this.quantity = quantity
        this.stock_id = stock_id
        this.type = type

        this.min = min
        this.max = max
        this.order = order
        this.picture = picture

        this.isRemovable = (this.min === 0)
        this.isBold = isBold

        this.weight = weight
        this.code = code
        // this.description = description
    }
}