import { AppService } from 'app/app.service'
import Moment from 'moment-timezone'

export class Price {

    public ratecard_product_id: number
    public price_wvat: number

    public monday: boolean = false
    public tuesday: boolean = false
    public wednesday: boolean = false
    public thursday: boolean = false
    public friday: boolean = false
    public saturday: boolean = false
    public sunday: boolean = false

    public start_at: string = "00:00:00"
    public end_at: string = "00:00:00"

    constructor (ratecard_product_id?: number, price_wvat?: number, days?: string, start_at = '00:00:00', end_at = '23:59:59') {
        this.ratecard_product_id = ratecard_product_id
        this.price_wvat = price_wvat

        if (days) {
            let daysArray = days.split(",")
            this.monday = (daysArray.indexOf("1") > -1)
            this.tuesday = (daysArray.indexOf("2") > -1)
            this.wednesday = (daysArray.indexOf("3") > -1)
            this.thursday = (daysArray.indexOf("4") > -1)
            this.friday = (daysArray.indexOf("5") > -1)
            this.saturday = (daysArray.indexOf("6") > -1)
            this.sunday = (daysArray.indexOf("7") > -1)
        }

        this.start_at = start_at
        this.end_at = end_at
    }

    public static format(price: Price): any {
        return {
            ratecard_product_id: price.ratecard_product_id,
            price_wvat: price.price_wvat,
            start_at: price.start_at,
            end_at: price.end_at,
            days: `${price.monday ? '1,' : ''}${price.tuesday ? '2,' : ''}${price.wednesday ? '3,' : ''}${price.thursday ? '4,' : ''}${price.friday ? '5,' : ''}${price.saturday ? '6,' : ''}${price.sunday ? '7' : ''}`
        }
    }
}