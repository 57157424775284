import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'
import { ProviderService } from 'app/services'
import { BillingDialog } from 'app/components/dialog/billing.dialog'
import { MatDialog } from '@angular/material'
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  template: `
  <i class="material-icons" style="cursor: pointer" (click)="handleClick()">search</i>
  <div class="ripple-container"></div>
  `,
})
export class BillingSearchRenderComponent implements ViewCell, OnInit {

  @Input() value: number
  @Input() rowData: any

  constructor (
      private ProviderService: ProviderService,
      private _Dialog: MatDialog,
      private _LoaderService: NgxUiLoaderService
  ) { }

  public ngOnInit (): void {
    // console.log(this.value)
  }

  public async handleClick(): Promise<void> {
    try {
        this._LoaderService.start()
        let billing = await this.ProviderService.findBilling(this.value)
        this._LoaderService.stop()
        this._Dialog.open(BillingDialog, {
            width: '800px',
            data: {
                title: `${(billing.status === "WAITED") ? "Bon de commande" : "Facture"} n°${this.value}`,
                billing: billing
            }
        })
    } catch (error) {
        console.error("BillingSearchRenderComponent::handleClick")
    }
}

}