import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Product, Vat, Type, PrinterPlacement,  } from 'app/models'
import { ProductService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ProductRenderComponent } from 'app/components/cell/render/product.render.component';
import { ProductPrice } from 'app/models/product/ProductPrice';
import { ProductDialog } from 'app/components/dialog/product.dialog';
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { ProductColorRenderComponent } from 'app/components/cell/render/productColor.render.component';
import { ProductColorEditorComponent } from 'app/components/cell/editor/productColor.editor.component';
import { ProductEditorComponent } from 'app/components/cell/editor/product.editor.component';
import { AppService } from 'app/app.service';
import { CopyRenderComponent } from 'app/components/cell/render/copy.render';
import { CopyEditorComponent } from 'app/components/cell/editor/copy.editor.component';

class ProductData {
    public product_id: number|boolean
    public name: string
    public vat: number|string
    public price_wvat: number|string
}

@Component({
    selector: 'table-extend',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.extend.component.css']
})
export class TableExtendComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            product_id: {
                title: 'Voir',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: ProductRenderComponent,
                editor : {
                    type: 'custom',
                    component: ProductEditorComponent
                }
            },
            code: {
                title: 'Code',
                type: 'text'
            },
            name: {
                title: 'Nom',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            // designation: {
            //     title: 'Nom long',
            //     type: 'text',
            // },
            // description: {
            //     title: 'Description',
            //     type: 'text',
            // },
            color: {
                title: 'Couleur',
                type: 'custom',
                renderComponent: ProductColorRenderComponent,
                editor: {
                    type: 'custom',
                    component: ProductColorEditorComponent
                }
            },
            // vat: {
            //     title: 'TVA',
            //     type: 'html',
            //     editor: {
            //       type: 'list',
            //       config: {
            //         list: AppService.vats.map(vat => { return {title: vat.label, value: vat.label}}),
            //       },
            //     }
            // },
            // price_wvat: {
            //     title: 'Prix TTC',
            //     type: 'text',
            // },
            printer_bold: {
                title: 'Impression en gras',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor : {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            available: {
                title: 'Disponible',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            copy: {
                title: 'Copier',
                filter: false,
                type: 'custom',
                renderComponent: CopyRenderComponent,
                onComponentInitFunction: (instance) => {
                    instance.copiedData.subscribe((productId) => {
                            this.handleCopy(productId)
                        })
                },
                editor: {
                    type: 'custom',
                    component: CopyEditorComponent
                }
            },
        }
    }
    
    @Input()
    public products: Array<Product>
    @Input()
    public type: string

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _ProductService: ProductService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.products.map(product => {
            return {
                product_id: product.product_id,
                name: product.name || '',
                code: product.code || '',
                designation: product.designation,
                description: product.description,
                type: product.type,
                color: product.color,
                printer_placement: product.printer_placement,
                available: product.available,
                printer_bold: product.printer_bold,
                // vat: this.getVat(product.product_price.vat),
                // price_wvat: product.product_price.price_wvat,
                // price_novat: product.product_price.price_novat,
                // ratecard_product_id: product.product_price.ratecard_product_id,
                picture: product.picture,
                icon: product.icon,
                copy: product.product_id
            }
        }))
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            $event.newData.price_novat = $event.newData.price_wvat / ((100 + this.getReverseVat($event.newData.vat)) / 100)
            let product = Object.assign(new Product(), {
                name: $event.newData.name,
                code: $event.newData.code,
                designation: $event.newData.designation,
                description: $event.newData.description,
                type: this.type,
                color: $event.newData.color,
                printer_placement: "COUNTER",
                printer_bold: $event.newData.printer_bold,
                // product_price: new ProductPrice(null, $event.newData.price_novat, $event.newData.price_wvat, this.getReverseVat($event.newData.vat).toString()),
                available: $event.newData.available,
                product_price: new ProductPrice(null, "0")
            })
            let res = await this._ProductService.add(product)
            let pr = await this._ProductService.find(res.lastInsertId)
            $event.newData.product_id = res.lastInsertId
            $event.newData.copy = res.lastInsertId
            $event.newData.ratecard_product_id = pr.product_price.ratecard_product_id
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableExtendComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout du produit.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            $event.newData.price_novat = $event.newData.price_wvat / ((100 + this.getReverseVat($event.newData.vat)) / 100)
            let product = Object.assign(new Product(), {
                name: $event.newData.name,
                code: $event.newData.code,
                designation: $event.newData.designation,
                description: $event.newData.description,
                type: $event.data.type,
                color: $event.newData.color,
                printer_placement: $event.data.printer_placement,
                printer_bold: $event.newData.printer_bold,
                // product_price: new ProductPrice($event.data.ratecard_product_id, $event.newData.price_novat, $event.newData.price_wvat, this.getReverseVat($event.newData.vat).toString()),
                available: $event.newData.available,
                picture: $event.data.picture,
                icon: $event.data.icon
            })
            await this._ProductService.edit($event.data.product_id, product)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableExtendComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la modification du produit.")
            return Promise.reject(error)
        }
    }
    
    public async handleCopy(productId: number): Promise<void> {
        try {
          this._LoaderService.start()
          const result = await this._ProductService.copy(productId)
          const copiedProduct = await this._ProductService.find(result.lastInsertId)
          await this.source.add(copiedProduct)
          await this.source.refresh()
          this._LoaderService.stop()
          this._ToasterService.success("Copie du produit effectué.")
          return Promise.resolve()
        } catch (error) {
          this._LoaderService.stop()
          if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
          else this._ToasterService.error("Erreur lors de la copie du produit.")
          console.error("CopyRenderComponent::handleCopy", error, this)
          return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._ProductService.delete($event.data.product_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableExtendComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du produit.")
            return Promise.reject(error)
        }
    }

    public getVat(vat): string {
        try {
            return AppService.vats.filter(v => v.value === parseFloat(vat))[0].label
        } catch (error) {
            return '0'
        }
    }
    public getReverseVat(label: string): number {
        try {
            return AppService.vats.filter(v => v.label === label)[0].value
        } catch (error) {
            return 0
        }
    }
    public getPlacement(placement: string): string {
        try {
            return AppService.placements.filter(v => v.value === placement)[0].label
        } catch (error) {
            'NO PLACEMENT'
        }
    }
    public getReversePlacement(label: string): string {
        try {
            return AppService.placements.filter(v => v.label === label)[0].value
        } catch (error) {
            'NO PLACEMENT'
        }
    }
}