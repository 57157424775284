import Moment from 'moment-timezone'

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Vat } from 'app/models';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Price } from 'app/models/product/Price';
import { AppService } from 'app/app.service';

@Component({
    selector: 'card-product-price',
    templateUrl: './card.productPrice.component.html',
    styleUrls: ['./card.productPrice.component.css']
})
export class CardProductPriceComponent implements OnInit {
    
    @Input() price: Price
    @Input() index: number
    @Input() isSet: boolean
    
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|Price|boolean>> = new EventEmitter
    
    public form: FormGroup
    public monday: AbstractControl
    public tuesday: AbstractControl
    public wednesday: AbstractControl
    public thursday: AbstractControl
    public friday: AbstractControl
    public saturday: AbstractControl
    public sunday: AbstractControl
    public start: AbstractControl
    public end: AbstractControl
    public price_wvat: AbstractControl

    public readonly: boolean = false
    public create: boolean = false

    public allDay: boolean

    constructor(
        private _FormBuilder: FormBuilder,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'monday': [true],
            'tuesday': [true],
            'wednesday': [true],
            'thursday': [true],
            'friday': [true],
            'saturday': [true],
            'sunday': [true],
            'start': ['11:00:00'],
            'end': ['23:00:00'],
            'price_wvat': ['0']
        })
        this.monday = this.form.controls['monday']
        this.tuesday = this.form.controls['tuesday']
        this.wednesday = this.form.controls['wednesday']
        this.thursday = this.form.controls['thursday']
        this.friday = this.form.controls['friday']
        this.saturday = this.form.controls['saturday']
        this.sunday = this.form.controls['sunday']
        this.start = this.form.controls['start']
        this.end = this.form.controls['end']
        this.price_wvat = this.form.controls['price_wvat']

        const start = Moment(this.price.start_at, "HH:mm:ss")
        const end = Moment(this.price.end_at, "HH:mm:ss")
        const diff = Moment(end).diff(Moment(start), "seconds")
        this.allDay = (diff === -1 || diff === 86399)
    }
    
    public handleToggle(): void {
        this.allDay = !this.allDay
        if (this.allDay) {
            this.price.start_at = Moment.utc("00:00:00", "HH:mm:ss").tz(AppService.user.timezone).format("HH:mm:ss")
            this.price.end_at = Moment.utc("23:59:59", "HH:mm:ss").tz(AppService.user.timezone).format("HH:mm:ss")
        } else {
            this.price.start_at = Moment("11:00:00", "HH:mm:ss").format("HH:mm:ss")
            this.price.end_at = Moment("23:00:00", "HH:mm:ss").format("HH:mm:ss")
        }
        this.onChange.emit([this.index, this.price])
    }
    public handleRemove(): void {
        this.onRemove.emit(this.index)
    }
    public handleChange(): void {
        this.price.price_wvat = parseFloat(this.price.price_wvat.toString().replace(",","."))
        this.onChange.emit([this.index, this.price])
    }
}