import { Injectable } from '@angular/core'
import { Httpclient } from "./Httpclient.service"

import { OptionCategory, Option, Comment, Vat } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class OptionService {

    public endpoint: string

    // private options: Array<Option>

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["options"].get()
    }

    public async get (): Promise<Array<Option>> {
        try {
            // if (cache && this.options && this.options.length > 0) return this.options

            let res = await this._Httpclient.get(`${this.endpoint}`)
            let options = res.map(option => new Option(
                option.config_category_id, 
                option.name, 
                option.code, 
                (option.kind === "TOGGLE")
                    ? option.value === "ENABLE"
                    : option.value, 
                option.description, 
                option.kind, 
                option.config_option_id
            ))
            
            // this.options = options
            return Promise.resolve(options)
        } catch (error) {
            console.error("OptionService:get", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async getVats (): Promise<Array<Vat>> {
        try {
            const options = await this.get()
            const vatOption = options.filter((option) => option.code === 'VAT')[0]
            if (typeof(vatOption.value) === 'boolean') throw new Error('vat is a boolean')
            return vatOption.value
                .split('|')
                .map((vat) => new Vat(`${vat} %`, parseFloat(vat)))
        } catch (error) {
            console.error("OptionService:getVats", error)
            const err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
    
    public async getCurrency (): Promise<string> {
        try {
            const options = await this.get()
            const currencyOption = options.filter((option) => option.code === 'CURRENCY')[0]
            if (typeof(currencyOption.value) === 'boolean') throw new Error('currency is a boolean')
            return currencyOption.value
        } catch (error) {
            console.error("OptionService:getCurrency", error)
            const err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
    
    public async getComment (): Promise<Array<Comment>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/comment`)
            let comments = res.map(comment => new Comment(comment.comment, comment.config_comment_id))
            return Promise.resolve(comments)
        } catch (error) {
            console.error("OptionService:getComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async getCategories (): Promise<Array<Option>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/category`)
            let categories = res.map(category => new OptionCategory(category.name, category.code, category.description, category.config_category_id))
            return Promise.resolve(categories)
        } catch (error) {
            console.error("OptionService:getCategories", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async addComment (comment: string): Promise<Status> {
        try {
            let data = {comment: comment}
            let res = await this._Httpclient.post(`${this.endpoint}/comment`, data)
            if (res.config_comment_id_id) res.lastInsertId = res.config_comment_id_id
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:addComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async update (options: Array<Option>): Promise<Status> {
        try {
            let data: Array<Option> = options.map(option => {
                return {
                    ...option,
                    value: (option.kind === "TOGGLE") 
                        ? (option.value) ? "ENABLE" : "DISABLE"
                        : option.value
                }
            })
            let res = await this._Httpclient.put(`${this.endpoint}`, data)
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:update", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async deleteComment (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/comment/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            console.error("OptionService:deleteComment", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
}