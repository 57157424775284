import { Injectable } from '@angular/core'
import { Httpclient } from './Httpclient.service'
import {AppConfigService} from "./AppConfigService.service";


@Injectable()
export class DeliverectService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["deliverect"].get()
    }

    // public async connectToDeliverect (accessKey: string): Promise<any> {
    //     try {
    //         const token = await this.authenticatev3(accessKey)

    //         let headers = new Headers();
    //         headers.append('Authorization', `Bearer ${token}`)

    //         const response = await this._Http.post('https://dev-deliverect.symbioz.io/v3/authentications', {}, {headers}).toPromise()
    //         return Promise.resolve(response.json())
    //     } catch (error) {
    //         return Promise.reject(error)
    //     }
    // }

    public async sendToDeliverect (): Promise<any> {
        try {
            // const token = await this.authenticatev3(accessKey)

            // let headers = new Headers();
            // headers.append('Authorization', `Bearer ${token}`)

            const response = await this._Httpclient.post(`${this.endpoint}/menu/configuration`, {})
            return Promise.resolve(response)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getAccounts (): Promise<any> {
        // const token = await this.authenticatev3(accessKey)

        // let headers = new Headers();
        // headers.append('Authorization', `Bearer ${token}`)

        const response = await this._Httpclient.get(`${this.endpoint}/accounts`)
        return Promise.resolve(response)
    }

    public async getLocations (): Promise<any> {
        // const token = await this.authenticatev3(accessKey)

        // let headers = new Headers();
        // headers.append('Authorization', `Bearer ${token}`)

        const response = await this._Httpclient.get(`${this.endpoint}/locations`)
        return Promise.resolve(response)
    }

    // private async authenticatev3 (accessKey: string): Promise<string> {
    //     try {
    //         let headers = new Headers();
    //         headers.append('Content-Type', 'application/json')
    //         headers.append('Domain', 'OPS')
    //         headers.append('Module', 'deliverect')
    
    //         const res = await this._Http.post('https://devlogin.symbioz.io/v3/login', {
    //             "username": "system",
    //             "password": "2bced1f5f0de72aa7accdf370e15a13c",
    //             "accessKey": accessKey
    //         }, {headers}).toPromise()
    
    //         return res.json().token   
    //     } catch (error) {
    //         return Promise.reject(error)
    //     }
    // }
}