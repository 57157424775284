import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'confirm-dialog',
    template: `
    <h1 mat-dialog-title>Etes vous sûr ?</h1>
    <div mat-dialog-content></div>
    <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Supprimer</button>
        <button mat-button (click)="onNoClick()">Annuler</button>
    </div>
    `,
  })
  export class ConfirmDialog {
  
    constructor(
      public dialogRef: MatDialogRef<ConfirmDialog>,
      @Inject(MAT_DIALOG_DATA) public data: string) {}
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  
  }