import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { ProductionLine } from 'app/models'
import { ProductionLineService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { ProductColorRenderComponent } from 'app/components/cell/render/productColor.render.component';
import { ProductColorEditorComponent } from 'app/components/cell/editor/productColor.editor.component';

@Component({
    selector: 'table-production-line',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.productionLine.component.css']
})
export class TableProductionLineComponent implements OnInit {

    public source: LocalDataSource//Array<ProductionLine>
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            name: {
                title: 'Nom',
                type: 'text',
            },
            code: {
                title: 'Code',
                type: 'text',
            },
            color: {
                title: 'Couleur',
                type: 'custom',
                renderComponent: ProductColorRenderComponent,
                editor: {
                    type: 'custom',
                    component: ProductColorEditorComponent
                }
            },
            icon: {
                title: 'Icon',
                type: 'text',
            },
        }
    }
    
    @Input()
    public productionLines: Array<ProductionLine>

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _ProductionLineService: ProductionLineService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.productionLines.map(productionLine => {
            return {
                production_line_id: productionLine.production_line_id,
                name: productionLine.name,
                code: productionLine.code,
                color: productionLine.color,
                icon: productionLine.icon,
            }
        }))
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let productionLine = Object.assign(new ProductionLine(), {
                name: $event.newData.name,
                code: $event.newData.code,
                color: $event.newData.color,
                icon: $event.newData.icon,
            })
            let res = await this._ProductionLineService.add(productionLine)
            $event.newData.production_line_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.source.data)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout de la chaine de production effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionLineComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout de la chaine de production.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let productionLine = Object.assign(new ProductionLine(), {
                name: $event.newData.name,
                code: $event.newData.code,
                color: $event.newData.color,
                icon: $event.newData.icon,
            })
            await this._ProductionLineService.edit($event.data.production_line_id, productionLine)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Edition de la chaine de production effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionLineComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'édition de la chaine de production.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._ProductionLineService.delete($event.data.production_line_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression de la chaine de production effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionLineComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression de la chaine de production.")
            return Promise.reject(error)
        }
    }
}