import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Product, Vat, Type } from 'app/models';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'app/app.service';
import { ProductPrice } from 'app/models/product/ProductPrice';

@Component({
    selector: 'card-product',
    templateUrl: './card.product.component.html',
    styleUrls: ['./card.product.component.css']
})
export class CardProductComponent implements OnInit {
    
    @Input() products: Array<Product>
    @Input() product: Product
    @Input() index: number 
    // @Input() types: Array<Type> 
    @Input() new: string
    @Input() count: number
    @Input() globalType: string
    @Input() readonly: boolean
    
    @Output() onEdit: EventEmitter<number> = new EventEmitter
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|Product|boolean>> = new EventEmitter
    @Output() onChangeOrder: EventEmitter<Array<number|boolean>> = new EventEmitter
    
    public form: FormGroup
    public name: AbstractControl
    public type: AbstractControl
    public price_wvat: AbstractControl
    public vat: AbstractControl

    public create: boolean = false
    public vats: Array<Vat> = AppService.vats
    public currency: string = AppService.currency


    constructor(
        private _FormBuilder: FormBuilder,
        private _LoaderService: NgxUiLoaderService,
        private _ProductService: ProductService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'name': ['', [Validators.required]],
            'type': ['', [Validators.required]],
            'price_wvat': [''],
            'vat': ['']
        })
        this.name = this.form.controls['name']
        this.type = this.form.controls['type']
        this.price_wvat = this.form.controls['price_wvat']
        this.vat = this.form.controls['vat']
        this.readonly = (this.readonly === undefined) ? true : this.readonly
    }

    public handleReduceOrder(): void {
        if (this.index === 0) return
        this.onChangeOrder.emit([this.index, this.product.order, this.product.order - 1, true])
    }
    public handleIncreaseOrder(): void {
        if (this.index === (this.count - 1)) return
        this.onChangeOrder.emit([this.index, this.product.order, this.product.order + 1, true])
    }
    public handleChangeOrder(): void {
        this.onChangeOrder.emit([this.index, this.product.order, this.product.order, false])
    }

    public handleVoid(): void {
        return
    }
    public handleEdit(): void {
        this.onEdit.emit(this.index)
    }
    public handleRemove(): void {
        this.onRemove.emit(this.index)
    }
    public handleChange(): void {
        if (!this.product.product_price) this.product.product_price = new ProductPrice(null,"0")
        if (!this.product.product_price.price_wvat) this.product.product_price.price_wvat = 0
        this.product.product_price.price_wvat = parseFloat(this.product.product_price.price_wvat.toString().replace(",","."))
        this.onChange.emit([this.index, this.product])
    }
    public handleSelect(): void {
        this.product = Object.assign(new Product(), this.products.filter(c => c.product_id === this.product.product_id)[0])
        if (this.product.type === 'SIMPLE') this.product.product_price.price_wvat = 0
        this.onChange.emit([this.index, this.product])
        this.product.order = this.index
    }
}