import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Stock, StockKind, Provider, Vat, Unit } from 'app/models'
import { StockService } from 'app/services'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { StockThresholdRenderComponent } from 'app/components/cell/render/stockThreshold.render.component'
import { StockRenderComponent } from 'app/components/cell/render/stock.render.component'
import { AppService } from 'app/app.service';

@Component({
    selector: 'table-stock',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.stock.component.css']
})
export class TableStockComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: false,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            nomination: {
                title: 'Nomination'
            },
            bar_code: {
                title: 'Code'
            },
            unit: {
                title: 'Unité',
                editor: {
                    type: 'list',
                    config: {
                        list: Unit.list.map(unit => { return {title: unit.label, value: unit.label}})
                    }
                }
            },
            unit_value: {
                title: 'Valeur unitaire'
            },
            quantity: {
                title: 'Quantité',
                editable: false,
                filter: false,
                type: 'custom',
                renderComponent: StockRenderComponent
            },
            price_novat: {
                title: 'Prix unit. HT',
                type: 'custom',
                renderComponent: StockRenderComponent
            },
            vat: {
                title: 'Taux de TVA',
                editor: {
                    type: 'list',
                    config: {
                        list: AppService.vats.map(vat => { return {title: vat.label, value: vat.label}})
                    }
                }
            },
            threshold: {
                title: 'Seuil de réappro',
                type: 'custom',
                renderComponent: StockThresholdRenderComponent
            },
            kindName: {
                title: 'Nature',
                editor: {
                    type: 'list',
                    config: {
                        list: []
                    }
                }
            },
            providerName: {
                title: 'Fournisseur',
                editor: {
                    type: 'list',
                    config: {
                        list: []
                    }
                }
            },
        }
    }

    @Input()
    public stocks: Array<Stock>
    @Input()
    public kinds: Array<StockKind>
    @Input()
    public providers: Array<Provider>

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _StockService: StockService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }


    public ngOnInit(): void {
        this.settings.columns.providerName.editor.config.list = this.providers.map(provider => { return {title: provider.corporate_name, value: provider.corporate_name}})
        this.settings.columns.kindName.editor.config.list = this.kinds.map(kind => { return {title: kind.name, value: kind.name}})

        this.stocks.shift()
        this.source = new LocalDataSource(this.stocks.map(stock => {
            return {
                ...stock,
                vat: this.getVat(stock.vat),
                unit: this.getUnit(stock.unit),
                providerName: this.getProvider(stock.provider_id),
                kindName: this.getKind(stock.stock_kind_id)
            }
        }))
    }
    private getUnit(value: string): string {
        let unit = Unit.list.filter(unit => unit.value === value)
        return (unit.length > 0) 
          ? unit[0].label
          : "UNIT"
    }
    private getReverseUnit(label: string): string {
        let unit = Unit.list.filter(unit => unit.label === label)
        return (unit.length > 0) 
          ? unit[0].value
          : "UNIT"
    }
    private getKind(id: number): string {
        let kind = this.kinds.filter(kind => kind.stock_kind_id === id)
        return (kind.length > 0) 
          ? kind[0].name
          : "AUTRE"
    }
    private getReverseKind(label: string): number {
        let kind = this.kinds.filter(kind => kind.name === label)
        return (kind.length > 0) 
          ? kind[0].stock_kind_id
          : 1
    }
    private getProvider(id: number): string {
        let provider = this.providers.filter(provider => provider.provider_id === id)
        return (provider.length > 0) 
          ? provider[0].corporate_name
          : "NO_PROVIDER"
    }
    private getReverseProvider(label: string): number {
        let provider = this.providers.filter(provider => provider.corporate_name === label)
        return (provider.length > 0) 
          ? provider[0].provider_id
          : 1
    }
    public getVat(vat): string {
        try {
            return AppService.vats.filter(v => v.value === parseFloat(vat))[0].label
        } catch (error) {
            return '0'
        }
    }
    private getReverseVat(label: string): number {
        let vat = AppService.vats.filter(vat => vat.label === label)
        return (vat.length > 0) 
          ? vat[0].value
          : 5.5
    }
    
    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let stock = {
                ...$event.newData,
                unit: this.getReverseUnit($event.newData.unit),
                provider_id: this.getReverseProvider($event.newData.providerName),
                stock_kind_id: this.getReverseKind($event.newData.kindName),
                vat: this.getReverseVat($event.newData.vat),
                // Depreciated
                price_wvat: parseFloat($event.newData.price_novat) * ((100 + this.getReverseVat($event.newData.vat)) / 100)
            }
            delete stock.providerName
            delete stock.kindName
            let res = await this._StockService.add(stock)
            $event.newData.stock_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableStockComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout du produit.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let stock = {
                ...$event.newData,
                unit: this.getReverseUnit($event.newData.unit),
                provider_id: this.getReverseProvider($event.newData.providerName),
                stock_kind_id: this.getReverseKind($event.newData.kindName),
                vat: this.getReverseVat($event.newData.vat),
                // Depreciated
                price_wvat: parseFloat($event.newData.price_novat) * ((100 + this.getReverseVat($event.newData.vat)) / 100)
            }
            delete stock.providerName
            delete stock.kindName
            await this._StockService.edit($event.data.stock_id, stock)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableStockComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lros de la modification du produit.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._StockService.delete($event.data.stock_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableStockComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du produit.")
            return Promise.reject(error)
        }
    }
    
}