export class Color {
    public label: string
    public value: string
    public rgb: string

    constructor (label: string, value: string, rgb: string) {
        this.label = label
        this.value = value
        this.rgb = rgb
    }

    public static list: Array<Color> = [
        new Color("Blanc", "COLOR_WHITE", "225,225,225"),
        new Color("Bleu", "COLOR_BLUE", "83,98,255"),
        new Color("Vert", "COLOR_GREEN", "0,255,11"),
        new Color("Rouge", "COLOR_RED", "255,14,0"),
        new Color("Cyan", "COLOR_CYAN", "0,248,255"),
        new Color("Jaune", "COLOR_YELLOW", "249,255,0"),
        new Color("Orange", "COLOR_ORANGE", "255,165,0"),
        new Color("Violet", "COLOR_PURPLE", "236,0,255"),
        new Color("Noir", "COLOR_BLACK", "30,30,30")
    ]
}