import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Stock, StockKind, Provider } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class StockService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["stock"].get()
    }

    public async get (): Promise<Array<Stock>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let stocks = res.map(stock => new Stock(
                parseInt(stock.stock_id), 
                stock.stock_kind_id,
                stock.provider_id,
                stock.bar_code,
                stock.nomination,
                stock.unit,
                parseFloat(stock.unit_value),
                parseFloat(stock.quantity),
                parseFloat(stock.vat),
                parseFloat(stock.price_novat),
                parseFloat(stock.price_wvat),
                parseInt(stock.threshold),
            ))
            return Promise.resolve(stocks)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getKind (): Promise<Array<StockKind>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/kind`)
            let kinds = res.map(kind => new StockKind(
                kind.stock_kind_id, 
                kind.name, 
                kind.order
            ))
            return Promise.resolve(kinds)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Stock> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let stock = new Stock(
                res.stock_id, 
                res.stock_kind_id,
                res.provider_id,
                res.bar_code,
                res.nomination,
                res.unit,
                res.unit_value,
                res.quantity,
                res.vat,
                res.price_novat,
                res.price_wvat,
                res.threshold,
                new StockKind(res.stock_kind.stock_kind_id, res.stock_kind.name, res.stock_kind.order),
                new Provider(
                    res.provider.provider_id,
                    res.provider.trade_name,
                    res.provider.corporate_name,
                    res.provider.siret,
                    res.provider.address,
                    res.provider.postal_code,
                    res.provider.city,
                    res.provider.phone,
                    res.provider.email,
                    res.provider.count
                )
            )
            return Promise.resolve(stock)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (stock: Stock): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}`, stock)
            if (res.stock_id) res.lastInsertId = res.stock_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async addKind (kind: StockKind): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/kind`, kind)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, stock: Stock): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, stock)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async deleteKind (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/kind/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}