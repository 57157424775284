import { Component, OnInit, Inject } from '@angular/core'
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material'

@Component({
  selector: 'bottomSheet-saver',
  templateUrl: './bottomSheet.saver.component.html',
  styleUrls: ['./bottomSheet.saver.component.css']
})
export class BottomSheetSaverComponent implements OnInit {

  public title: string

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetSaverComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  public ngOnInit(): void {
    
  }
  
  public handleClick(): void {
    this.bottomSheetRef.dismiss({ data: this.data })
    event.preventDefault()
  }
}
