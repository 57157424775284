import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Status } from 'app/models/Status'
import { Customer } from "app/models"
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class CustomerService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["customer"].get()
    }

    public async get (): Promise<Array<Customer>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let customers = res.map(customer => new Customer(
                customer.customer_id,
                customer.first_name,
                customer.last_name,
                customer.address,
                customer.postal_code,
                customer.city,
                customer.phone,
                customer.email,
                customer.comment
                
            ))
            return Promise.resolve(customers)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (customer: Customer): Promise<Status> {
        try {
            let body = {...customer}
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.customer_id) res.lastInsertId = res.customer_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, customer: Customer): Promise<Status> {
        try {
            let body = {...customer}
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}