import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'export-dialog',
    template: `
    <h1 mat-dialog-title>Demander un export</h1>
    <p>L'envoi peut prendre un certain temps, vérifiez votre boite mail régulièrement...</p>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width">
            <input matInput id="email" [(ngModel)]="data.email" placeholder="Votre email">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <select style="width: 100%;height: 2rem;background-color: white;border-top: none;border-left: none;border-right: none;-webkit-appearance: menulist !impor
    tant;" [(ngModel)]="data.exportType">
            <option style="color: #3C4858;" value="LABEL" disabled>Séléctionnez un type d'export</option>
            <option style="color: #3C4858;" value="TVA">TVA & Modes de paiements</option>
            <option style="color: #3C4858;" value="RESTAURANT_TICKETS">Tickets restaurants</option>
            <option style="color: #3C4858;" value="TRANSACTIONS">Transactions</option>
            <option style="color: #3C4858;" value="CANCELLATIONS">Annulations</option>
            <option style="color: #3C4858;" value="CANCELLATIONS_AND_REDUCTIONS">Annulations et réductions</option>
            <option style="color: #3C4858;" value="SELL_AND_CONSO">Canaux de consommation</option>
            <option style="color: #3C4858;" value="SESSIONS">Sessions d'encaissement</option>
            <option style="color: #3C4858;" value="SALES">Analyse des ventes</option>
            <option style="color: #3C4858;" value="SALES_BY_STAFFS">Analyse des ventes par employés</option>
            <option style="color: #3C4858;" value="SALES_BY_ORIGINS">Analyse des ventes par canaux de vente</option>
            <option style="color: #3C4858;" value="SALES_BY_CODES">Analyse des ventes par canaux de consommation</option>
            <option style="color: #3C4858;" value="SALES_BY_FAMILIES">Analyse des ventes par famille de produits</option>
            <option style="color: #3C4858;" value="CUSTOMERS">Analyse des ventes par clients</option>
          </select>
        </div>
      </div>
      <div class="container" *ngIf="data.exportType && data.exportType !== 'LABEL'">
        <br />
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="pickerStart" placeholder="Date de début" [(ngModel)]="data.startDate">
              <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
              <mat-datepicker #pickerStart disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="pickerEnd" placeholder="Date de fin" [(ngModel)]="data.endDate">
              <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Annuler</button>
      <button mat-button [mat-dialog-close]="data" cdkFocusInitial *ngIf="data.startDate && data.endDate">Valider</button>
    </div>
    `,
  })
  export class ExportDialog {
  
    constructor(
      public dialogRef: MatDialogRef<ExportDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.data = { exportType: "LABEL" }
      }
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  
  }