export class Kind {
    public label: string
    public value: string

    constructor (label: string, value: string) {
        this.label = label
        this.value = value
    }

    public static list: Array<Kind> = [
        new Kind("Non modifiable", "BRUT"),
        new Kind("Modifiable", "COMPOSED"),
        new Kind("A composer", "VERSED")
    ]
}