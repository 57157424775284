import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Stock, ProductItem } from 'app/models';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'card-item',
    templateUrl: './card.item.component.html',
    styleUrls: ['./card.item.component.css']
})
export class CardItemComponent implements OnInit {
    
    @Input() item: ProductItem
    @Input() stocks: Array<Stock>
    @Input() count: number
    @Input() index: number 
    
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|ProductItem|boolean>> = new EventEmitter
    @Output() onChangeOrder: EventEmitter<Array<number>> = new EventEmitter
    
    public stock: Stock
    public form: FormGroup
    public name: AbstractControl
    public stock_id: AbstractControl
    public picture: AbstractControl
    public isRemovable: AbstractControl
    public isBold: AbstractControl
    public weight: AbstractControl
    
    public create: boolean = false

    constructor(
        private _FormBuilder: FormBuilder,
        private _LoaderService: NgxUiLoaderService,
        private _ProductService: ProductService,
        private _Dialog: MatDialog
    ) { }

    public ngOnInit(): void {
        this.create = !this.item.product_item_id
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'name': ['', [Validators.required]],
            'stock_id': ['', [Validators.required]],
            'picture': [''],
            'isRemovable': [false],
            'isBold': [false],
            'weight': ['', [Validators.required]]
        })
        this.name = this.form.controls['name']
        this.stock_id = this.form.controls['stock_id']
        this.picture = this.form.controls['picture']
        this.isRemovable = this.form.controls['isRemovable']
        this.isBold = this.form.controls['isBold']
        this.weight = this.form.controls['weight']

        this.stock = this.stocks.find(s => s.stock_id === this.item.stock_id)
        if (!this.create) {
            this.item.weight = parseFloat((this.item.weight * this.stock.unit_value).toFixed(5))
        }
    }

    public handleReduceOrder(): void {
        if (this.index === 0) return
        this.onChangeOrder.emit([this.index, this.item.order, this.item.order - 1])
    }
    public handleIncreaseOrder(): void {
        if (this.index === (this.count - 1)) return
        this.onChangeOrder.emit([this.index, this.item.order, this.item.order + 1])
    }

    public handleSelect(): void {
        this.stock = this.stocks.filter(s => s.stock_id === this.item.stock_id)[0]
    }

    public async handleRemove(): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this.onRemove.emit(this.index)
        } catch (error) {
            console.error("CardItemComponent::handleRemove")
        }
    }
    public handleSubmit(): void {
        if (this.form.valid) {
            this.onChange.emit([this.index,this.item])
            this.create = false
        }
    }

    public handleChange(): void {
        this.onChange.emit([this.index,this.item])
    }
}