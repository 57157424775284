import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

interface Data {
    title: string
    date: string
    inventory: {
        inventory_line: {
            stock: {
                nomination: string
            }
            theoritical_quantity: string
            real_quantity: string
        }[]
    }
}

@Component({
    selector: 'inventory-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <p>Date : {{data.date}}</p>
      <table class="table table-hover">
        <thead class="text-info">
            <th class="table-action">Element de stock</th>
            <th>Quantité théorique</th>
            <th>Quantité réel</th>
            <th>Différence</th>
        </thead>
        <tbody>
            <tr *ngFor="let line of data.inventory.inventory_line">
                <td>{{line.stock.nomination}}</td>
                <td>{{line.theoritical_quantity}}</td>
                <td>{{line.real_quantity}}</td>
                <td>{{line.theoritical_quantity - line.real_quantity}}</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Fermer</button>
    </div>
    `,
  })
  export class InventoryDialog implements OnInit {

    constructor(
      public dialogRef: MatDialogRef<InventoryDialog>,
      @Inject(MAT_DIALOG_DATA) public data: Data) {}

    public ngOnInit(): void {
    }

    public onNoClick(): void {
      this.dialogRef.close();
    }

  }
