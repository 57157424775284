export class StockKind {
    public stock_kind_id: number
    public name: string
    public order: number

    constructor (stock_kind_id?: number, name?: string, order?: number) {
        this.stock_kind_id = stock_kind_id
        this.name = name
        this.order = order
    }
}