import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { ProductionPlacement, PrinterPlacement } from 'app/models'
import { ProductionPlacementService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { ProductColorRenderComponent } from 'app/components/cell/render/productColor.render.component';
import { ProductColorEditorComponent } from 'app/components/cell/editor/productColor.editor.component';
import { AppService } from 'app/app.service';

@Component({
    selector: 'table-production-placement',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.productionPlacement.component.css']
})
export class TableProductionPlacementComponent implements OnInit {

    public source: LocalDataSource//Array<ProductionPlacement>
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            name: {
                title: 'Nom',
                type: 'text',
            },
            code: {
                title: 'Code',
                type: 'text',
            },
            color: {
                title: 'Couleur',
                type: 'custom',
                renderComponent: ProductColorRenderComponent,
                editor: {
                    type: 'custom',
                    component: ProductColorEditorComponent
                }
            },
            icon: {
                title: 'Icon',
                type: 'text',
            },
            order: {
                title: 'Ordre',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            }
        }
    }
    
    @Input()
    public productionPlacements: Array<ProductionPlacement>

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _ProductionPlacementService: ProductionPlacementService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.productionPlacements
            .map(productionPlacement => {
                return {
                    production_placement_id: productionPlacement.production_placement_id,
                    name: productionPlacement.name,
                    code: productionPlacement.code,
                    color: productionPlacement.color,
                    icon: productionPlacement.icon,
                    order: productionPlacement.order,
                }
            }))
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let productionPlacement = Object.assign(new ProductionPlacement(), {
                name: $event.newData.name,
                code: $event.newData.code,
                color: $event.newData.color,
                icon: $event.newData.icon,
                order: $event.newData.order,
            })
            let res = await this._ProductionPlacementService.add(productionPlacement)
            $event.newData.production_placement_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.source.data)
            this._LoaderService.stop()
            const productionPlacements = await this._ProductionPlacementService.get()
            AppService.placements = productionPlacements
                // .filter((productionPlacement) => productionPlacement.code !== 'PICKING')
                .map((prodctionPlacement) => new PrinterPlacement(prodctionPlacement.name, prodctionPlacement.code))
            this._ToasterService.success("Ajout du poste de préparation effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionPlacementComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout du poste de préparation.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let productionPlacement = Object.assign(new ProductionPlacement(), {
                name: $event.newData.name,
                code: $event.newData.code,
                color: $event.newData.color,
                icon: $event.newData.icon,
                order: $event.newData.order,
            })
            await this._ProductionPlacementService.edit($event.data.production_placement_id, productionPlacement)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            const productionPlacements = await this._ProductionPlacementService.get()
            AppService.placements = productionPlacements
            // .filter((productionPlacement) => productionPlacement.code !== 'PICKING')
                .map((prodctionPlacement) => new PrinterPlacement(prodctionPlacement.name, prodctionPlacement.code))
            this._ToasterService.success("Edition du poste de préparation effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionPlacementComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'édition du poste de préparation.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._ProductionPlacementService.delete($event.data.production_placement_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            const productionPlacements = await this._ProductionPlacementService.get()
            AppService.placements = productionPlacements
                // .filter((productionPlacement) => productionPlacement.code !== 'PICKING')
                .map((prodctionPlacement) => new PrinterPlacement(prodctionPlacement.name, prodctionPlacement.code))
            this._ToasterService.success("Suppression du poste de préparation effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableProductionPlacementComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du poste de préparation.")
            return Promise.reject(error)
        }
    }
}