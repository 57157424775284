import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { CategoryProduct, Product } from 'app/models'
import { CategoryService, ProductService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ProductRenderComponent } from 'app/components/cell/render/product.render.component';
import { ProductPrice } from 'app/models/product/ProductPrice';
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { ProductColorEditorComponent } from 'app/components/cell/editor/productColor.editor.component';
import { ProductColorRenderComponent } from 'app/components/cell/render/productColor.render.component';
import { Kind } from 'app/models/product/Kind';
import { ProductEditorComponent } from 'app/components/cell/editor/product.editor.component';
import { AppService } from 'app/app.service';
import { CopyRenderComponent } from 'app/components/cell/render/copy.render';
import { CopyEditorComponent } from 'app/components/cell/editor/copy.editor.component';
import { CategoryRenderComponent } from 'app/components/cell/render/category.render.component';
import { CategoryEditorComponent } from 'app/components/cell/editor/category.editor.component';

class ProductData {
    public product_id: number|boolean
    public name: string
    public vat: number|string
    public price_wvat: number|string
}

@Component({
    selector: 'table-categoryProduct',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.categoryProduct.component.css']
})
export class TableCategoryProductComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            id: {
                title: 'Voir',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: CategoryRenderComponent,
                editor : {
                    type: 'custom',
                    component: CategoryEditorComponent
                }
            },
            code: {
                title: 'Code',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            name: {
                title: 'Nom',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            designation: {
                title: 'Nom Long',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            description: {
                title: 'Description',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            min: {
                title: 'Minimum',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            max: {
                title: 'maximum',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            copy: {
                title: 'Copier',
                filter: false,
                type: 'custom',
                renderComponent: CopyRenderComponent,
                onComponentInitFunction: (instance) => {
                    instance.copiedData.subscribe((categoryId) => {
                        this.handleCopy(categoryId)
                    })
                },
                editor: {
                    type: 'custom',
                    component: CopyEditorComponent
                }
            },
        }
    }
    
    @Input()
    public categories: Array<CategoryProduct>
    @Input()
    public type: string

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _CategoryService: CategoryService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.categories.map(category => {
            return {
                id: (category.type === "SET") ? category.category_set_id : category.category_extend_id,
                name: category.name || '',
                code: category.code || '',
                designation: category.designation,
                description: category.description,
                type: category.type,
                min: category.min,
                max: category.max,
                copy: (category.type === "SET") ? category.category_set_id : category.category_extend_id
            }
        }))

    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let category = Object.assign(new CategoryProduct(), {
                code: $event.newData.code,
                name: $event.newData.name,
                designation: $event.newData.designation,
                description: $event.newData.description,
                type: this.type,
                min: $event.newData.min,
                max: $event.newData.max
            })
            let res, cat
            if (category.type === "SET") {
                res = await this._CategoryService.addSet(category)
                cat = await this._CategoryService.findSet(res.lastInsertId)
            } else {
                res = await this._CategoryService.addExtend(category)
                cat = await this._CategoryService.findExtend(res.lastInsertId)
            }
            $event.newData.id = res.lastInsertId
            $event.newData.copy = res.lastInsertId
            await $event.confirm.resolve({
                ...$event.newData,
                type: this.type
            })
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout de la catégorie effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCategoryProductComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout de la catégorie.")
            return Promise.reject(error)
        }
    }
    
    public async handleCopy(categoryId: number): Promise<void> {
        try {
          this._LoaderService.start()
          const result = await this._CategoryService.copy(categoryId, this.type)
          console.log(result)
          let copiedCategory
          if (this.type === "SET") {
            copiedCategory = await this._CategoryService.findSet(result.lastInsertId)
          } else {
            copiedCategory = await this._CategoryService.findExtend(result.lastInsertId)
          }
          await this.source.add({
            ...copiedCategory,
            id: (copiedCategory.type === "SET") ? copiedCategory.category_set_id : copiedCategory.category_extend_id,
            copy: (copiedCategory.type === "SET") ? copiedCategory.category_set_id : copiedCategory.category_extend_id
          })
          await this.source.refresh()
          this._LoaderService.stop()
          this._ToasterService.success("Copie de la catégorie effectué.")
          return Promise.resolve()
        } catch (error) {
          this._LoaderService.stop()
          if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
          else this._ToasterService.error("Erreur lors de la copie de la catégorie.")
          console.error("CopyRenderComponent::handleCopy", error, this)
          return Promise.reject(error)
        }
    }
    
    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let category = Object.assign(new CategoryProduct(), {
                code: $event.newData.code,
                name: $event.newData.name,
                designation: $event.newData.designation,
                description: $event.newData.description,
                type: this.type,
                min: $event.newData.min,
                max: $event.newData.max,
            })
            if (category.type === "SET") {
                await this._CategoryService.editSet($event.data.id, category)
            } else {
                await this._CategoryService.editExtend($event.data.id, category)
            }
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification du produit effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCategoryProductComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la modification du produit.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()

            if (this.type === "SET") {
                await this._CategoryService.deleteSet($event.data.id)
            } else {
                await this._CategoryService.deleteExtend($event.data.id)
            }
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression de la catégorie effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCategoryProductComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du produit.")
            return Promise.reject(error)
        }
    }
}