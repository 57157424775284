import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { ProductionPlacement } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class ProductionPlacementService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["production"].get()
    }

    public async get (): Promise<Array<ProductionPlacement>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/placements`)
            let productionPlacements = res.map(productionPlacement => new ProductionPlacement(
                productionPlacement.name,
                productionPlacement.code,
                productionPlacement.color,
                productionPlacement.icon,
                productionPlacement.order,
                productionPlacement.production_placement_id
            ))
            return Promise.resolve(productionPlacements)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<ProductionPlacement> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/placements/${id}`)
            let productionPlacement = new ProductionPlacement(
                res.name,
                res.code,
                res.color,
                res.icon,
                res.order,
                res.production_placement_id
            )
            return Promise.resolve(productionPlacement)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (productionPlacement: ProductionPlacement): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/placements`, productionPlacement)
            if (res.production_placement_id) res.lastInsertId = res.production_placement_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, productionPlacement: ProductionPlacement): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/placements/${id}`, productionPlacement)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/placements/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}