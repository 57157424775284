import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'
import { InventoryService } from 'app/services'
import { InventoryDialog } from 'app/components/dialog/inventory.dialog'
import { MatDialog } from '@angular/material'
import { NgxUiLoaderService } from 'ngx-ui-loader'


@Component({
  template: `
  <i class="material-icons" style="cursor: pointer" (click)="handleClick()">search</i>
  <div class="ripple-container"></div>
  `,
})
export class InventorySearchRenderComponent implements ViewCell, OnInit {

  @Input() value: number
  @Input() rowData: any

  constructor (
        private _InventoryService: InventoryService,
        private _Dialog: MatDialog,
        private _LoaderService: NgxUiLoaderService
  ) { }

  public ngOnInit (): void {

  }

  public async handleClick(): Promise<void> {
    try {
        this._LoaderService.start()
        let inventory = await this._InventoryService.find(this.value)
        this._LoaderService.stop()
        this._Dialog.open(InventoryDialog, {
            width: '800px',
            data: {
                title: `Inventaire n°${this.value}`,
                date: inventory.date,
                inventory: inventory
            }
        })
    } catch (error) {
        console.error("InventorySearchRenderComponent::handleClick")
    }
}

}