import { AppService } from 'app/app.service'
import Moment from 'moment-timezone'

export class OpeningHour {
    public id: number
    public startAt: string
    public endAt: string
    public days: Array<number>

    public monday: boolean
    public tuesday: boolean
    public wednesday: boolean
    public thursday: boolean
    public friday: boolean
    public saturday: boolean
    public sunday: boolean

    constructor (startAt?: string, endAt?: string, days?: Array<number>, id?: number) {
        this.id = id
        this.startAt = Moment.utc(startAt, "HH:mm:ss").tz(AppService.user.timezone).format("HH:mm:ss")
        this.endAt = Moment.utc(endAt, "HH:mm:ss").tz(AppService.user.timezone).format("HH:mm:ss")
        this.days = days || [1,2,3,4,5,6,7]


        if (days) {
            this.monday = (days.indexOf(1) > -1)
            this.tuesday = (days.indexOf(2) > -1)
            this.wednesday = (days.indexOf(3) > -1)
            this.thursday = (days.indexOf(4) > -1)
            this.friday = (days.indexOf(5) > -1)
            this.saturday = (days.indexOf(6) > -1)
            this.sunday = (days.indexOf(7) > -1)
        }

    }

    public static format(openingHour: OpeningHour): any {
        const result: any = {
            id: openingHour.id,
            startAt: Moment(openingHour.startAt, "HH:mm:ss", AppService.user.timezone).utc().format("HH:mm:ss"),
            endAt: Moment(openingHour.endAt, "HH:mm:ss", AppService.user.timezone).utc().format("HH:mm:ss"),
            days: []
        }

        if (openingHour.monday) result.days.push(1)
        if (openingHour.tuesday) result.days.push(2)
        if (openingHour.wednesday) result.days.push(3)
        if (openingHour.thursday) result.days.push(4)
        if (openingHour.friday) result.days.push(5)
        if (openingHour.saturday) result.days.push(6)
        if (openingHour.sunday) result.days.push(7)

        return result
    }
}