import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { Cell, DefaultEditor, Editor } from 'ng2-smart-table';
import { Color } from 'app/models';


@Component({
  template: `
    <select class="product-select" [(ngModel)]="value" (change)="handleChange()">
        <option value="" class="product-option" disabled selected>Couleur</option>
        <option *ngFor="let c of colors" class="product-option {{ c.value }}" [ngValue]="c.value">
          {{c.label}} 
        </option>
    </select>
  `,
  styleUrls: ['./../cell.component.css']
})
export class ProductColorEditorComponent extends DefaultEditor implements OnInit {
  
  public value: string
  public colors: Array<Color> = Color.list

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.value = this.cell.getValue()
  }

  public handleChange(): void {
    this.cell.newValue = this.value
  }
}