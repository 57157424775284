import { Stock } from "./Stock"

export class InventoryLine {
    public inventory_line_id: number
    public inventory_id: number
    public stock_id: number
    public theoritical_quantity: number
    public real_quantity: number
    public stock: Stock

    constructor(
        inventory_line_id?: number,
        inventory_id?: number,
        stock_id?: number,
        theoritical_quantity?: number,
        real_quantity?: number,
        stock?: Stock
    ) {
        this.inventory_line_id = inventory_line_id
        this.inventory_id = inventory_id
        this.stock_id = stock_id
        this.theoritical_quantity = theoritical_quantity
        this.real_quantity = real_quantity
        this.stock = stock
    }
}