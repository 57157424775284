import { Injectable } from '@angular/core'
import { Httpclient } from "./Httpclient.service"

import { Family } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class FamilyService {

    public endpoint: string

    // private options: Array<Family>

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["family"].get()
    }
    
    public async get (): Promise<Array<Family>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let families = res.map(family => new Family(family.name, family.id))
            return Promise.resolve(families)
        } catch (error) {
            console.error("FamilyService:get", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async add (name: string): Promise<Status> {
        try {
            let data = { name }
            let res = await this._Httpclient.post(`${this.endpoint}`, data)
            if (res.id) res.lastInsertId = res.id
            return Promise.resolve(res)
        } catch (error) {
            console.error("FamilyService:add", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            console.error("FamilyService:delete", error)
            let err = await this._Httpclient.error(error)
            return Promise.reject(err)
        }
    }
}