import { StockKind } from "./StockKind";
import { Provider } from "./Provider";

export class Stock {
    public stock_id: number
    public stock_kind_id: number
    public provider_id: number
    public bar_code: string
    public nomination: string
    public unit: string
    public unit_value: number
    public quantity: number
    public vat: number
    public price_novat: number
    public price_wvat: number
    public threshold: number
    public stock_kind: StockKind
    public provider: Provider

    constructor (stock_id?: number, stock_kind_id?: number, provider_id?: number, bar_code?: string, nomination?: string, unit?: string, unit_value?: number, quantity?: number, vat?: number, price_novat?: number, price_wvat?: number, threshold?: number, stock_kind?: StockKind, provider?: Provider) {
        this.stock_id = stock_id
        this.stock_kind_id = stock_kind_id
        this.provider_id = provider_id
        this.bar_code = bar_code
        this.nomination = nomination
        this.unit = unit
        this.unit_value = unit_value
        this.quantity = quantity
        this.vat = vat
        this.price_novat = price_novat
        this.price_wvat = price_wvat
        this.threshold = threshold
        this.stock_kind = stock_kind
        this.provider = provider
    }
}