import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'


@Component({
  template: `
  <i class="material-icons" [ngStyle]="{'color':value}">lens</i>
  <div class="ripple-container"></div>
  `,
})
export class ColorRenderComponent implements ViewCell, OnInit {

  @Input() value: string
  @Input() rowData: any

  constructor () { }

  public ngOnInit (): void {

  }

}