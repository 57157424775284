export class ProductionPlacement {
    public production_placement_id: number
    public name: string
    public code: string
    public color: string
    public icon: string
    public order: number

    constructor(name?: string, code?: string, color?: string, icon?: string, order?: number, production_placement_id?: number) {
        this.production_placement_id = production_placement_id
        this.name = name
        this.code = code
        this.color = color
        this.icon = icon
        this.order = order
    }
}