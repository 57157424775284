import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell } from 'ng2-smart-table'
import { PasswordDialog } from 'app/components/dialog/password.dialog';
import { Router } from '@angular/router';


@Component({
  template: `
  <i class="material-icons" style="cursor: pointer" (click)="handleClick()">search</i>
  <div class="ripple-container"></div>
  `,
})
export class ProductRenderComponent implements ViewCell, OnInit {

  @Input() value: string
  @Input() rowData: any

  constructor (
    private _Router: Router
  ) { }

  public ngOnInit (): void {

  }

  public handleClick(): void {
    this._Router.navigateByUrl(`/sales/product/${this.value}`)
  }

}

