import { Injectable } from '@angular/core'
import { Md5 } from 'ts-md5'

import { Httpclient } from './Httpclient.service'
import { Status } from 'app/models/Status'
import { Staff } from "app/models"
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class StaffService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["staff"].get()
    }

    public async get (): Promise<Array<Staff>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/all`)
            let staffs = res.map(staff => new Staff(
                staff.staff_id,
                staff.first_name,
                staff.last_name,
                staff.role,
                `rgb(${staff.color.split(',').map(c => c.split('.')[0]).join(',')})`,
                staff.email,
                staff.login,
                staff.phone,
                staff.reduction === "TRUE",
                staff.cash_drawer === "TRUE",
                staff.remove_order === "TRUE",
                staff.remove_quantity === "TRUE",
                staff.payment === "TRUE",
                staff.password,
                staff.socialSecurityNumber,
                staff.last_print === "TRUE",
            ))
            return Promise.resolve(staffs)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Staff> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let staff = new Staff(
                res.staff_id,
                res.first_name,
                res.last_name,
                res.role,
                `rgb(${res.color.split(',').map(c => c.split('.')[0]).join(',')})`,
                res.email,
                res.login,
                res.phone,
                res.reduction === "TRUE",
                res.cash_drawer === "TRUE",
                res.remove_order === "TRUE",
                res.remove_quantity === "TRUE",
                res.payment === "TRUE",
                res.password,
                res.socialSecurityNumber,
                res.last_print === "TRUE",
            )
            return Promise.resolve(staff)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (staff: Staff): Promise<Status> {
        try {
            let password = (staff.password == '' && staff.oldPassword != '') 
                ? staff.oldPassword
                : Md5.hashStr((staff.password != '') ? staff.password : '123456')
            let body = {
                ...staff,
                color: staff.color.substr(4, staff.color.length - 5),
                reduction: staff.reduction ? "TRUE" : "FALSE",
                cash_drawer: staff.cash_drawer ? "TRUE" : "FALSE",
                remove_order: staff.remove_order ? "TRUE" : "FALSE",
                remove_quantity: staff.remove_quantity ? "TRUE" : "FALSE",
                payment: staff.payment ? "TRUE" : "FALSE",
                password: password,
                socialSecurityNumber: staff.socialSecurityNumber,
                last_print: staff.last_print ? "TRUE" : "FALSE",
            }
            delete body.oldPassword
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.staff_id) res.lastInsertId = res.staff_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, staff: Staff): Promise<Status> {
        try {
            let password = (staff.password == '') 
                ? staff.oldPassword
                : Md5.hashStr(staff.password)
            let body = {
                ...staff,
                color: staff.color.substr(4, staff.color.length - 5),
                reduction: staff.reduction ? "TRUE" : "FALSE",
                cash_drawer: staff.cash_drawer ? "TRUE" : "FALSE",
                remove_order: staff.remove_order ? "TRUE" : "FALSE",
                remove_quantity: staff.remove_quantity ? "TRUE" : "FALSE",
                payment: staff.payment ? "TRUE" : "FALSE",
                password,
                socialSecurityNumber: staff.socialSecurityNumber,
                last_print: staff.last_print ? "TRUE" : "FALSE",
            }
            delete body.oldPassword
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}