import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Checkout } from 'app/models'
import { CheckoutService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
    selector: 'table-checkout',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.checkout.component.css']
})
export class TableCheckoutComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        // hideHeader: true,
        actions: {
            title: '',
            add: true,
            edit: true,
            delete: true
        },
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            checkout_id: {
                title: 'Identifiant',
                filter: false,
                editable: false
            },
            name: {
                title: 'Nom',
                filter: false
            }
        }
    }
    
    @Input()
    public checkouts: Array<Checkout>
    @Input()
    public type: string

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _CheckoutService: CheckoutService,
        private _LoaderService: NgxUiLoaderService,
        private _ToasterService: ToastrService,
        private _Dialog: MatDialog
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.checkouts.map(c => c))
        if ((this.type == "ESTORE" || this.type == "DELIVERY") && this.checkouts.map(c => c).length === 1) {
            this.settings.actions.add = false
        }
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            $event.newData = Object.assign(new Checkout(), {name: $event.newData.name, code: this.type, category_id: 1})
            let res = await this._CheckoutService.add($event.newData)
            $event.newData.checkout_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout du point d'encaissement effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCheckoutComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Ereur lors de l'ajout du point d'encaissement.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            await this._CheckoutService.edit($event.data.checkout_id, $event.newData)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Edition du point d'encaissement effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCheckoutComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Ereur lors de l'édition du point d'encaissement.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._CheckoutService.delete($event.data.checkout_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression du point d'encaissement effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableCheckoutComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Ereur lors de la suppression du point d'encaissement.")
            return Promise.reject(error)
        }
    }
    
}