import {enableProdMode, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getRemoteConfig, getValue, fetchAndActivate} from "firebase/remote-config";
import { environment } from '../../environments/environment'
@Injectable()
export class AppConfigService {

    public endpoints: Endpoints
    public username: string;
    public password: string;
    public exportUrl: string;

    constructor(private http: HttpClient) {}

    async load(): Promise<void>  {
        if(environment.config) {
            this.endpoints = new Endpoints(environment.config.endpoint, environment.config.auth_endpoint)
            this.username = environment.config.username
            this.password = environment.config.password
            this.exportUrl = environment.config.exportUrl
        } else {
            // Initialize Firebase
            const app = initializeApp(environment.firebase);
            getAnalytics(app);
            const remoteConfig = getRemoteConfig();
            // remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    
            remoteConfig.defaultConfig = {
                endpoint: "http://localhost:3000",
                auth_endpoint: "http://localhost:3001"
            }
    
             await fetchAndActivate(remoteConfig).catch((err) => {
                alert("Error")
                console.error(err)
                throw err
            })
    
            this.endpoints = new Endpoints(getValue(remoteConfig, "endpoint").asString(), getValue(remoteConfig, "auth_endpoint").asString())
            this.username = getValue(remoteConfig, "username").asString()
            this.password = getValue(remoteConfig, "password").asString()
            this.exportUrl = getValue(remoteConfig, "exportUrl").asString()
        }

    }
}

class Endpoints {
    public auth = new Endpoints.Endpoint("Authentification", this.endpoint, "auth")

    public common = new Endpoints.Endpoint("Common", this.endpoint, "manager", "parameter")

    public kpi = new Endpoints.Endpoint("Kpi", this.endpoint, "manager", "kpi")

    public product = new Endpoints.Endpoint("Product", this.endpoint, "manager", "card/product")
    public category = new Endpoints.Endpoint("Category", this.endpoint, "manager", "card/category")

    public stock = new Endpoints.Endpoint("Stock", this.endpoint, "manager", "store/stock")
    public provider = new Endpoints.Endpoint("Provider", this.endpoint, "manager", "store/provider")
    public inventory = new Endpoints.Endpoint("Inventory", this.endpoint, "manager", "store/inventory")

    public family = new Endpoints.Endpoint("Family", this.endpoint, "manager", "parameter/family")
    public deliverect = new Endpoints.Endpoint("Deliverect", this.endpoint, "manager", "parameter/deliverect")
    public checkout = new Endpoints.Endpoint("Checkout", this.endpoint, "manager", "parameter/checkout")
    public hour = new Endpoints.Endpoint("Opening Hour", this.endpoint, "manager", "parameter/hour")
    public staff = new Endpoints.Endpoint("Staff", this.endpoint, "manager", "parameter/staff")
    public printer = new Endpoints.Endpoint("Printer", this.endpoint, "manager", "parameter/printer")
    public production = new Endpoints.Endpoint("Production", this.endpoint, "manager", "parameter/production")
    public options = new Endpoints.Endpoint("Options", this.endpoint, "manager", "parameter/option")
    public establishment = new Endpoints.Endpoint("Establishment", this.endpoint, "manager", "parameter/establishment")
    public customer = new Endpoints.Endpoint("Customer", this.endpoint, "manager", "parameter/customer")

    constructor(private endpoint: string, private auth_endpoint: string) {}

    static Endpoint = class {
        public name: string
        public basepath: string
        public module: string
        public resource: string
        public port: number


        constructor (name: string, basepath: string, module?: string, resource?: string, port?: number) {
            this.name = name
            this.basepath = basepath
            this.module = module
            this.resource = resource
            this.port = port
        }

        public get(): string {
            let path: string = this.basepath
            if (this.port) path += `:${this.port}`
            if (this.module) path += `/${this.module}`
            if (this.resource) path += `/${this.resource}`
            return path
        }
    }
}