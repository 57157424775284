import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Provider, ProviderBilling, ProviderBillingLine } from "app/models"
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class ProviderService {

    public endpoint: string

    constructor(
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["provider"].get()
    }

    public async get (): Promise<Array<Provider>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let providers = res.map(provider => new Provider(
                provider.provider_id,
                provider.trade_name,
                provider.corporate_name,
                provider.siret,
                provider.address,
                provider.postal_code,
                provider.city,
                provider.phone,
                provider.email,
                provider.count
            ))
            return Promise.resolve(providers)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getBilling (): Promise<Array<Provider>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/billing?lines=FALSE`)
            let billings = res.map(billing => new ProviderBilling(
                billing.provider_billing_id,
                billing.provider_id,
                billing.editing_date,
                billing.payment_date,
                billing.total_novat,
                billing.total_wvat,
                billing.total_vat,
                billing.status
            ))
            return Promise.resolve(billings)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getBillingByPoviderId (id: number): Promise<Array<Provider>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}/billing`)
            let billings = res.map(billing => new ProviderBilling(
                billing.provider_billing_id,
                billing.provider_id,
                billing.editing_date,
                billing.payment_date,
                billing.total_novat,
                billing.total_wvat,
                billing.total_vat,
                billing.status,
                billing.provider_billing_line.map(line => new ProviderBillingLine(
                    line.provider_billing_line_id,
                    line.provider_billing_id,
                    line.stock_id,
                    line.total_novat,
                    line.vat,
                    line.total_wvat,
                    line.quantity,
                    line.stock
                ))
            ))
            return Promise.resolve(billings)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Provider> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let provider = new Provider(
                res.provider_id,
                res.trade_name,
                res.corporate_name,
                res.siret,
                res.address,
                res.postal_code,
                res.city,
                res.phone,
                res.email,
                res.count
            )
            return Promise.resolve(provider)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async findBilling (id: number): Promise<ProviderBilling> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/billing/${id}`)
            let billing = new ProviderBilling(
                res.provider_billing_id,
                res.provider_id,
                res.editing_date,
                res.payment_date,
                res.total_novat,
                res.total_wvat,
                res.total_vat,
                res.status,
                res.provider_billing_line.map(line => new ProviderBillingLine(
                    line.provider_billing_line_id,
                    line.provider_billing_id,
                    line.stock_id,
                    line.total_novat,
                    line.vat,
                    line.total_wvat,
                    line.quantity,
                    line.stock
                ))
            )
            return Promise.resolve(billing)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    public async add (provider: Provider): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}`, provider)
            if (res.provider_id) res.lastInsertId = res.provider_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, provider: Provider): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, provider)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }
}