import { Stock } from "./Stock";

export class ProviderBillingLine {

    public provider_billing_line_id: number
    public provider_billing_id: number
    public stock_id: number
    public total_novat: number
    public vat: number
    public total_wvat: number
    public quantity: number
    public stock: Stock

    constructor (
        provider_billing_line_id?: number,
        provider_billing_id?: number,
        stock_id?: number,
        total_novat?: number,
        vat?: number,
        total_wvat?: number,
        quantity?: number,
        stock?: Stock
    ) {
        this.provider_billing_line_id = provider_billing_line_id
        this.provider_billing_id = provider_billing_id
        this.stock_id = stock_id
        this.total_novat = total_novat
        this.vat = vat
        this.total_wvat = total_wvat
        this.quantity = quantity
        this.stock = stock
    }
}