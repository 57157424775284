export class ProductionLine {
    public production_line_id: number
    public name: string
    public code: string
    public color: string
    public icon: string
    public order: number

    constructor(name?: string, code?: string, color?: string, icon?: string, order?: number, production_line_id?: number) {
        this.production_line_id = production_line_id
        this.name = name
        this.code = code
        this.color = color
        this.icon = icon
        this.order = order
    }
}