import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service';
import {AppConfigService} from "./AppConfigService.service";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class KpiService {

    public endpoint: string
    public exportUrl: string

    constructor (
        private _Httpclient: Httpclient,
        private config: AppConfigService,
        private _ToastrService: ToastrService
    ) {
        this.endpoint = config.endpoints["kpi"].get()
        this.exportUrl = config.exportUrl
    }

    public async export (data: {email: string, exportType: string, startDate: string, endDate: string}): Promise<any> {
        try {
            let res = await this._Httpclient.post(this.exportUrl, data)
            this._ToastrService.success("Un export vient de vous être envoyé, vérifiez vos emails.")
            return Promise.resolve(res)
        } catch (error) {
            this._ToastrService.error("Une erreur lors de la génération de votre export a été détecté.")
            return Promise.reject(error)
        }
    }

    public async getDay (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/day`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getWeek (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/week`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getMonth (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/month`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getDayBefore (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/day/before`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getWeekBefore (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/week/before`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async getMonthBefore (): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/month/before`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    public async exportDay (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/day?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportWeek (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/week?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportMonth (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/month?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportDayBefore (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/day/before?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportWeekBefore (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/week/before?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportMonthBefore (mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/month/before?mail=${mail}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    public async exportMonthDate (month: string, year: string, mail: string = ""): Promise<Array<any>> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/month?mail=${mail}&month=${month}&year=${year}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}