import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell, DefaultEditor } from 'ng2-smart-table'
import { PasswordDialog } from 'app/components/dialog/password.dialog';


@Component({
  template: `
  <i class="material-icons" style="cursor: pointer" (click)="handleClick()">vpn_key</i>
  <div class="ripple-container"></div>
  `,
})
export class PasswordEditorComponent extends DefaultEditor implements AfterViewInit {
  

  constructor(
    private _Dialog: MatDialog
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    
  }

  public async handleClick(): Promise<void> {
    try {
      let dialog = this._Dialog.open(PasswordDialog, {
        width: '500px',
        data: {password: ''}
      })
      let result = await dialog.afterClosed().toPromise()
      if (result) this.cell.newValue = result
    } catch (error) {
      console.error("PasswordEditorComponent::handleClick")
    }
  }

}

