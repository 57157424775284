import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { EstablishmentService } from 'app/services'

import { ColorRenderComponent } from 'app/components/cell/render/color.render.component'
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component'
import { PasswordRenderComponent } from 'app/components/cell/render/password.render.component'

import { ColorEditorComponent } from 'app/components/cell/editor/color.editor.component'
import { PasswordEditorComponent } from 'app/components/cell/editor/password.editor.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { User } from 'app/models';

@Component({
    selector: 'table-user',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.user.component.css']
})
export class TableUserComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: false,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            firstName: {
                title: 'Prénom'
            },
            lastName: {
                title: 'Nom'
            },
            phone: {
                title: 'Téléphone'
            },
            email: {
                title: 'e-mail'
            }
        }
    }
    
    @Input()
    public users: Array<User>
    @Input()
    public establishment: number

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _EstablishmentService: EstablishmentService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }


    public ngOnInit(): void {
        this.source = new LocalDataSource(this.users)
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let user = {...$event.newData}
            let res = await this._EstablishmentService.addUser(this.establishment, user)
            $event.newData.userId = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout de l'utilisateur effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableUserComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout de l'utilisateur.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let user = {...$event.newData}
            await this._EstablishmentService.updateUser(this.establishment, $event.data.userId, user)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification de l'utilisateur effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableUserComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur loRs de la modification de l'utilisateur.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._EstablishmentService.deleteUser(this.establishment, $event.data.userId)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression de l'utilisateur effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableUserComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression de l'utilisateur.")
            return Promise.reject(error)
        }
    }
    
}