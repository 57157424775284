import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Printer, PrinterTarget, PrinterKind, PrinterModel } from 'app/models'
import { PrinterService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { AppService } from 'app/app.service';

class PrinterData {
    public name: string
    public kind: string
    public placement: string
    public model: string
    public target: string
}

@Component({
    selector: 'table-printer',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.printer.component.css']
})
export class TablePrinterComponent implements OnInit {

    public source: LocalDataSource//Array<PrinterData>
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            name: {
                title: 'Nom',
                type: 'text',
            },
            kind: {
                title: 'Type',
                type: 'html',
                editor: {
                  type: 'list',
                  config: {
                    list: PrinterKind.list.map(kind => { return {title: kind.label, value: kind.label} }),
                  },
                }
            },
            productionPlacement: {
                title: 'Emplacement',
                type: 'html',
                editor: {
                  type: 'list',
                  config: {
                    list: AppService.placements.map((placement) => { return {title: placement.label, value: placement.label} })
                  },
                }
            },
            model: {
                title: 'Modèle',
                type: 'html',
                editor: {
                  type: 'list',
                  config: {
                    list: PrinterModel.list.map(placement => { return {title: placement.value, value: placement.value} }),
                  },
                }
            },
            cash_drawer: {
                title: 'Tiroir caisse',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            buzzer: {
                title: 'Buzzer intégré',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            ticket_pass: {
                title: 'Ticket passe-plat',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            ticket_product: {
                title: 'Ticket Produit',
                type: 'custom',
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            target: {
                title: 'Adresse',
                type: 'text',
            }
        }
    }
    
    @Input()
    public printers: Array<Printer>
    @Input()
    public type: string

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _PrinterService: PrinterService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { 
        
    }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.printers.map(printer => {
            return {
                printer_id: printer.printer_id,
                name: printer.name,
                kind: this.getKind(printer.kind),
                productionPlacement: this.getPlacement(printer.placement),
                model: (printer.target.length > 0) ? printer.target[0].model : '',
                target: (printer.target.length > 0) ? printer.target[0].mac_address : '',
                cash_drawer: printer.cash_drawer,
                buzzer: printer.buzzer,
                ticket_pass: printer.ticket_pass,
                ticket_product: printer.ticket_product
            }
        }))
    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let printer = Object.assign(new Printer(), {
                name: $event.newData.name,
                kind: this.getReversKind($event.newData.kind),
                placement: this.getReversePlacement($event.newData.productionPlacement),
                target: [new PrinterTarget($event.newData.model, $event.newData.target)],
                cash_drawer: $event.newData.cash_drawer,
                buzzer: $event.newData.buzzer,
                ticket_pass: $event.newData.ticket_pass,
                ticket_product: $event.newData.ticket_product
            })
            let res = await this._PrinterService.add(printer)
            $event.newData.printer_id = res.lastInsertId
            await $event.confirm.resolve($event.newData)
            this.onCreate.emit($event.source.data)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout de l'imprimante effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TablePrinterComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout de l'imprimante.")
            return Promise.reject(error)
        }
    }

    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let printer = Object.assign(new Printer(), {
                name: $event.newData.name,
                kind: this.getReversKind($event.newData.kind),
                placement: this.getReversePlacement($event.newData.productionPlacement),
                target: [new PrinterTarget($event.newData.model, $event.newData.target)],
                cash_drawer: $event.newData.cash_drawer,
                buzzer: $event.newData.buzzer,
                ticket_pass: $event.newData.ticket_pass,
                ticket_product: $event.newData.ticket_product
            })
            await this._PrinterService.edit($event.data.printer_id, printer)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Edition de l'imprimante effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TablePrinterComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'édition de l'imprimante.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._PrinterService.delete($event.data.printer_id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression de l'imprimante effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TablePrinterComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression de l'imprimante.")
            return Promise.reject(error)
        }
    }


    public getPlacement(code: string): string {
        let placement = AppService.placements.filter(data => data.value === code)
        return (placement.length > 0) 
        ? placement[0].label
        : "Aucune placement"
    }
    public getKind(code: string): string {
        let kind = PrinterKind.list.filter(data => data.value === code)
        return (kind.length > 0) 
        ? kind[0].label
        : "Aucune type"
    }
    public getReversePlacement(label: string): string {
        let placement = AppService.placements.filter(data => data.label === label)
        return (placement.length > 0) 
        ? placement[0].value
        : "Aucune placement"
    }
    public getReversKind(label: string): string {
        let kind = PrinterKind.list.filter(data => data.label === label)
        return (kind.length > 0) 
        ? kind[0].value
        : "Aucune type"
    }
    
}