import { Component, Input, OnInit, Inject } from '@angular/core'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

interface Data {
    password: string
}

@Component({
    selector: 'staff-password-dialog',
    template: `
    <h1 mat-dialog-title>Changer le mot de passe</h1>
    <div mat-dialog-content>
        <p>Nouveau mot de passe</p>
        <mat-form-field>
            <input matInput [(ngModel)]="data.password">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Annuler</button>
        <button mat-button [mat-dialog-close]="data.password" cdkFocusInitial>Valider</button>
    </div>
    `,
  })
  export class PasswordDialog {

    constructor(
      public dialogRef: MatDialogRef<PasswordDialog>,
      @Inject(MAT_DIALOG_DATA) public data: Data) {}

    public onNoClick(): void {
      this.dialogRef.close();
    }

  }
