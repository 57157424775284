import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'


@Component({
  template: `
  <input type="hidden" name="toggle" [(ngModel)]="value">
  <toggle [on]="value" [readonly]="true"></toggle>
  `,
})
export class ToggleRenderComponent implements ViewCell, OnInit {

  @Input() value: any
  @Input() rowData: any

  constructor () { }

  public ngOnInit (): void {
    
  }
}