import { Input, Component, OnInit } from "@angular/core";
import { Inventory, InventoryLine } from "app/models";
import { InventorySearchRenderComponent } from "app/components/cell/render/inventory.search.render.component";
import { LocalDataSource } from "ng2-smart-table";

@Component({
    selector: 'table-inventory',
    template: `
    <ng2-smart-table 
        [settings]="settings"
        [source]="source"
    ></ng2-smart-table>
    `,
    // templateUrl: `./table.inventory.component.html`,
    styleUrls: ['./table.inventory.component.css']
})
export class TableInventoryComponent implements OnInit {

    @Input()
    public inventories: Array<Inventory>
    @Input()
    public kind: string

    public source:  LocalDataSource

    public settings = {
        actions: {
            columnTitle: '',
            add: false,
            edit: false,
            delete: false
        },
        columns: {
            inventory_id: {
                title: 'Voir',
                filter: false,
                type: 'custom',
                renderComponent: InventorySearchRenderComponent
            },
            date: {
                title: 'Date de l\'inventaire'
            },
            staff: {
                title: 'Réalisé par'
            }
        }
    }

    public constructor () { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.inventories.map(inventory => {
            return {
                inventory_id: inventory.inventory_id,
                date: inventory.date,
                staff: `${inventory.staff.first_name} ${inventory.staff.last_name}`
            }
        }))
    }
}