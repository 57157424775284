import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell } from 'ng2-smart-table'
import { PasswordDialog } from 'app/components/dialog/password.dialog';
import { Router } from '@angular/router';


@Component({
  template: `
  {{ value }}
  `,
})
export class StockRenderComponent implements ViewCell, OnInit {

  @Input() value: string
  @Input() rowData: any

  constructor (
    private _Router: Router
  ) { }

  public ngOnInit (): void {
    this.value = parseFloat(this.value).toFixed(2)
  }

}

