import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ViewCell, DefaultEditor } from 'ng2-smart-table'
import { CopyDialog } from 'app/components/dialog/copy.dialog';


@Component({
  template: `
  <i class="material-icons" style="color: #ccc; cursor: pointer" (click)="handleClick()">content_copy</i>
  <div class="ripple-container"></div>
  `,
})
export class CopyEditorComponent extends DefaultEditor implements AfterViewInit {
  

  constructor(
    private _Dialog: MatDialog
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    
  }

  public async handleClick(): Promise<void> {
    try {
      let dialog = this._Dialog.open(CopyDialog, {
        width: '500px',
        data: {copy: false}
      })
      let result = await dialog.afterClosed().toPromise()
      if (result) this.cell.newValue = result
    } catch (error) {
      console.error("CopyEditorComponent::handleClick")
    }
  }

}

