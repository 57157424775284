import { Injectable } from '@angular/core'
import { Http, Headers } from '@angular/http';
import { AppService } from 'app/app.service';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class LoginService {

    private endpoint: string

    constructor(
        private _Http: Http,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["auth"].get()
    }

    public async auth (accessKey: string, version: string): Promise<void> {
        try {
            let crmToken = localStorage.getItem('admin-token') || 0

            let headers = new Headers();
            headers.append("Domain", "MANAGER")
            headers.append("Module", "manager")
            headers.append("Content-Type", "application/json")
            headers.append("Version", version)
            headers.append("Authorization", `Basic ${window.btoa(`${this.config.username}:${this.config.password}`)}`)
            headers.append('x-access-token', `Bearer ${crmToken}`)
            let res = await this._Http.post(`${this.endpoint}/system/login`, { accessKey }, { headers }).toPromise()
            localStorage.setItem("token", res.json().token)
            AppService.user = this.parseToken(localStorage.getItem("token"))
            return Promise.resolve()
        } catch (error) {
            console.error("LoginService:auth", error)
            return Promise.reject(error)
        }
    }

    public async login (user: any): Promise<void> {
        try {
            let headers = new Headers();
            headers.append("Domain", "BACKOFF")
            headers.append("Content-Type", "application/json")
            let res = await this._Http.post(`${this.endpoint}/sbz/login`, user, {headers}).toPromise()
            AppService.userAdmin = this.parseToken(res.json().token)
            localStorage.setItem("admin-token", res.json().token)
            return Promise.resolve()
        } catch (error) {
            console.error("LoginService:login", error)
            return Promise.reject(error)
        }
    }

    private parseToken (token: string): any {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
}