export class Provider {
    public provider_id: number
    public trade_name: string
    public corporate_name: string
    public siret: string
    public address: string
    public postal_code: string
    public city: string
    public phone: string
    public email: string
    public count: number

    constructor (provider_id?: number, trade_name?: string, corporate_name?: string, siret?: string, address?: string, postal_code?: string, city?: string, phone?: string, email?: string, count?: number) {
        this.provider_id = provider_id
        this.trade_name = trade_name
        this.corporate_name = corporate_name
        this.siret = siret
        this.address = address
        this.postal_code = postal_code
        this.city = city
        this.phone = phone
        this.email = email
        this.count = count
    }
}