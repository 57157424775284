import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { Cell, DefaultEditor, Editor } from 'ng2-smart-table';


@Component({
  template: `
  <mat-form-field class="example-full-width" style="width: 35px">
    <input matInput 
      placeholder="Couleur"
      (colorPickerChange)="handleChange($event)"
      [(ngModel)]="value" 
      [(colorPicker)]="value" 
      [cpOutputFormat]="'rgba'" 
      [cpAlphaChannel]="'disabled'" 
      [ngStyle]="{'color': this.value, 'background-color': this.value}">
  </mat-form-field>
  `,
})
export class ColorEditorComponent extends DefaultEditor implements OnInit {
  
  public value: string

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.value = this.cell.getValue()
  }

  public handleChange($event): void {
    this.cell.newValue = this.value = $event
  }
}