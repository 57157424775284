import { Establishment } from "app/models/establishment/Establishment"
import { Vat, PrinterPlacement, Family } from "./models"

export class AppService {
    public static establishment: Establishment
    public static user: {role: string, timezone: string}
    public static userAdmin: any
    public static vats: Array<Vat>
    public static placements: Array<PrinterPlacement>
    public static families: Array<Family>
    public static currency: string
}
