import { Injectable } from '@angular/core'

import { Checkout } from "app/models"
import { Httpclient } from './Httpclient.service';
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class CheckoutService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["checkout"].get()
    }

    public async get (): Promise<Array<Checkout>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let checkouts = res.map(checkout => new Checkout(
                checkout.checkout_id,
                checkout.name,
                checkout.code,
                (checkout.auto_print === "TRUE"),
                (checkout.auto_purchase === "TRUE"),
                (checkout.auto_preparation === "TRUE"),
            ))
            return Promise.resolve(checkouts)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number, moduleName: string): Promise<Checkout> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}?module=${moduleName}&noproduct=TRUE`)
            let checkout = new Checkout(
                res.checkout_id,
                res.name,
                res.code,
                (res.auto_print === "TRUE"),
                (res.auto_purchase === "TRUE"),
                (res.auto_preparation === "TRUE"),
                parseInt(res.category_id)
            )
            return Promise.resolve(checkout)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (checkout: Checkout): Promise<Status> {
        try {
            let body = {
                ...checkout,
                auto_print: (checkout.auto_print) ? "TRUE" : "FALSE",
                auto_purchase: (checkout.auto_purchase) ? "TRUE" : "FALSE",
                auto_preparation: (checkout.auto_preparation) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.checkout_id) res.lastInsertId = res.checkout_id
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, checkout: Checkout): Promise<Status> {
        try {
            let body = {
                ...checkout,
                auto_print: (checkout.auto_print) ? "TRUE" : "FALSE",
                auto_purchase: (checkout.auto_purchase) ? "TRUE" : "FALSE",
                auto_preparation: (checkout.auto_preparation) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}