import { Association } from "./Association";
import { Product } from "..";

export class Category {
    // Common
    public category_id: number
    public name: string
    public code: string
    public product: Array<Product>
    public isRoot: boolean
    public iconCode: string
    public designation: string
    public description: string
    public category: Array<Category>
    public kind: string
    public order: number
    public plu: string

    constructor (name?: string, code?: string, category_id?: number, product: Array<Product> = [], isRoot: boolean = false, iconCode?: string, designation?: string, description?: string, category?: Array<Category>, kind?: string, order?: number, plu?: string) {
        this.name = name
        this.product = product
        this.category_id = category_id
        this.isRoot = isRoot
        this.iconCode = iconCode
        this.designation = designation
        this.description = description
        this.category = category
        this.kind = kind
        this.order = order
        this.code = code
        this.plu= plu    
    }
}