export class Customer {

    public customer_id: number
    public first_name: string
    public last_name: string
    public address: string
    public postal_code: string
    public city: string
    public phone: string
    public email: string
    public comment: string
    

    constructor (
        customer_id?: number,
        first_name?: string,
        last_name?: string,
        address?: string,
        postal_code?: string,
        city?: string,
        phone?: string,
        email?: string,
        comment?: string
    ) {
        this.customer_id = customer_id
        this.first_name = first_name
        this.last_name = last_name
        this.address = address
        this.postal_code = postal_code
        this.city = city
        this.phone = phone
        this.email = email
        this.comment = comment
    }
}

