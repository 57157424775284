import { Environment } from "./type";

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBgD2o5_ZgGqel8XmG9hNRG505F14KNDRU",
    authDomain: "symbioz-dev.firebaseapp.com",
    projectId: "symbioz-dev",
    storageBucket: "symbioz-dev.appspot.com",
    messagingSenderId: "1013834495807",
    appId: "1:1013834495807:web:9bbd7f4c6606f83b6ec58a",
    measurementId: "G-ENKTPLEDMM"
  }
};
