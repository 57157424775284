import { ProviderBillingLine } from "./ProviderBillingLine";

export class ProviderBilling {
    public provider_billing_id: number
    public provider_id: number
    public editing_date: string
    public payment_date: string
    public total_novat: string
    public total_wvat: string
    public total_vat: string
    public status: string
    public provider_billing_line: Array<ProviderBillingLine>

    constructor(
        provider_billing_id?: number,
        provider_id?: number,
        editing_date?: string,
        payment_date?: string,
        total_novat?: string,
        total_wvat?: string,
        total_vat?: string,
        status?: string,
        provider_billing_line?: Array<ProviderBillingLine>
    ) {
        this.provider_billing_id = provider_billing_id
        this.provider_id = provider_id
        this.editing_date = editing_date
        this.payment_date = payment_date
        this.total_novat = total_novat
        this.total_wvat = total_wvat
        this.total_vat = total_vat
        this.status = status
        this.provider_billing_line = provider_billing_line
    }
}