export class Establishment {

    public static default: Establishment = new Establishment(null, null, null, null, false)

    public establishment_id: number
    public name: string
    public accessKey: string
    public active: boolean
    public version: string
    public build_number: string
    
    public deliverect_location_id: string
    public deliverect_account_id: string
    // public deliverect_client_id: string
    // public deliverect_client_secret: string
    
    public place_id: string
    
    public trade_name: string
    public corporate_name: string
    public tva: string
    public siret: string
    public naf: string
    public longitude: string
    public latitude: string
    public phone: string
    public address: string
    public postal_code: string
    public city: string
    public countryCode: string

    constructor (
        establishment_id?: number,
        name?: string, 
        accessKey?: string,
        version?: string,
        active: boolean = true, 

        trade_name?: string, 
        corporate_name?: string, 
        tva?: string,
        siret?: string,
        naf?: string,
        longitude?: string,
        latitude?: string,
        phone?: string,
        address?: string,
        postal_code?: string,
        city?: string,
        build_number?: string,

        deliverect_location_id?: string,
        deliverect_account_id?: string,
        // deliverect_client_id?: string,
        // deliverect_client_secret?: string,

        place_id?: string,

        countryCode?: string
    ) {
        this.establishment_id = establishment_id
        this.name = name
        this.accessKey = accessKey
        this.version = version
        this.build_number = build_number
        this.active = active

        this.trade_name = trade_name
        this.corporate_name = corporate_name
        this.tva = tva
        this.siret = siret
        this.naf = naf
        this.longitude = longitude
        this.latitude = latitude
        this.phone = phone
        this.address = address
        this.postal_code = postal_code
        this.city = city

        this.deliverect_location_id = deliverect_location_id
        this.deliverect_account_id = deliverect_account_id
        // this.deliverect_client_id = deliverect_client_id
        // this.deliverect_client_secret = deliverect_client_secret

        this.place_id = place_id

        this.countryCode = countryCode
    }
}