export class Checkout {

    public checkout_id: number
    public name: string
    public code: string
    
    public auto_print: boolean
    public auto_purchase: boolean
    public auto_preparation: boolean

    public category_id: number
    
    constructor (checkout_id?: number, name?: string, code?: string, auto_print: boolean = false, auto_purchase: boolean = false, auto_preparation: boolean = false, category_id?: number) {
        this.checkout_id = checkout_id
        this.name = name
        this.code = code

        this.auto_print = auto_print
        this.auto_purchase = auto_purchase
        this.auto_preparation = auto_preparation

        this.category_id = category_id
    }
}