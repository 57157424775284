import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Option } from 'app/models';

@Component({
    selector: 'card-option',
    templateUrl: './card.option.component.html',
    styleUrls: ['./card.option.component.css']
})
export class CardOptionComponent {
    
    @Input() option: Option
    @Input() index: number 
    
    @Output() onChange: EventEmitter<Option> = new EventEmitter

    constructor() { }

    public handleChange(): void {
        this.onChange.emit(this.option)
    }

    public handleToggle(index: number): void {
        this.option.value = !this.option.value
        this.handleChange()
    }
}